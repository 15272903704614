import DashboardMenu from "../../commonComponents/DashboardMenu";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Autocomplete, TextField, Tooltip, Zoom, Button } from "@mui/material";
import Box from "@mui/material/Box";
import useStyles from "../DashboardAuction/dashAuction.style";
import { toast } from "react-toastify";
import { getToken } from "../../../common";

import axios from "axios";
import React, { useState } from "react";
import config from "../../../config/config";
import io from "socket.io-client";
import moment from "moment";
import momentTimeZone from "moment-timezone";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

import "react-data-table-component-extensions/dist/index.css";
import "react-toastify/dist/ReactToastify.css";

const Mount = React.memo(({ condition, children }) => {
  if (condition === true) {
    return children;
  }
});

const capitalize = (str) => {
  const capitalizedWords = str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  return capitalizedWords.join(" ");
};

const Index = () => {
  const classes = useStyles();
  const getZone = momentTimeZone.tz.guess();
  const [auctions, updateAuctions] = useState([]);
  const [sellers, updateSellers] = useState([]);
  const [connected, setConnected] = useState(false);
  const userDetails = (() =>
    JSON.parse(localStorage.getItem("userData") ?? `{}`))();
  const [seller, selectSeller] = React.useState(
    ((details) => {
      if (details.role === "SELLER") {
        return { label: details.username, id: details.userId };
      }
      return {};
    })(userDetails)
  );

  const liveAuctions = async () => {
    try {
      if (!seller.id) {
        return void 0;
      }

      const { data } = await axios.get(
        `${config.api.base_url}dashboard/sellers/${seller.id}/live-auctions`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: getToken(),
          },
        }
      );

      updateAuctions(data.auctions);
    } catch (error) {
      toast(error.message);
    }
  };

  const sellerList = async () => {
    try {
      if (userDetails.role === "SELLER") {
        return void 0;
      }

      const response = await axios.get(
        `${config.api.base_url}dashboard/live-auctions-sellers`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: getToken(),
          },
        }
      );

      updateSellers([
        ...response.data.map((details) => {
          return {
            label: capitalize(
              `${details.firstName} ${details.lastName} (${details.totalLots})`
            ),
            id: details.userId,
          };
        }),
      ]);
    } catch (error) {
      toast(error.message);
    }
  };

  React.useEffect(() => {
    sellerList();
    return () => void 0;
  }, []);
  React.useEffect(() => {
    liveAuctions();
    return () => void 0;
  }, [seller]);
  React.useEffect(() => {
    const socket = io(config.api.socket_url, {
      extraHeaders: { authorization: getToken() },
    });

    socket.on("connect", (...props) => {
      /*console.log('connected',props);*/ setConnected(true);
    });
    socket.on("disconnect", (...props) => {
      /*console.log('disconnected',props);*/ setConnected(false);
    });
    socket.on(seller.id, (...props) => {
      /*console.log('props',props);*/ liveAuctions();
    });

    return () => {
      socket.disconnect();
    };
  }, [seller]);

  const columns = [
    {
      name: "Sr No",
      selector: (data) => Number(data.id),
      cell: (data) => String(data.id),
      width: "4%",
      sortable: true,
    },
    {
      name: "Auction Name",
      width: "180px",
      selector: (data) => data.auctionName.toLowerCase(), // Ensure proper sorting
      cell: (data) => (
        <Tooltip
          title={data.auctionName}
          placement="top-start"
          TransitionComponent={Zoom}
        >
          <span className={classes.ellipsisText}>{data.auctionName}</span>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: "Lot No.",
      width: "90px",
      selector: (data) => Number(data.lotNumber), // Ensure numeric sorting
      cell: (data) => (
        <Tooltip
          title={data.lotNumber}
          placement="top-start"
          TransitionComponent={Zoom}
        >
          <span className={classes.ellipsisText}>{data.lotNumber}</span>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: "Prebid",
      width: "90px",
      selector: (data) => Number(data.previewPeriod), // Ensure numeric sorting
      cell: (data) => String(data.previewPeriod),
      sortable: true,
    },
    {
      name: "Lot description ",
      width: "150px",
      selector: (data) => data.shortDescription.toLowerCase(), // Ensure proper sorting
      cell: (data) => (
        <Tooltip
          title={data.shortDescription}
          placement="top-start"
          TransitionComponent={Zoom}
        >
          <span className={classes.ellipsisText}>{data.shortDescription}</span>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: "Unit",
      width: "80px",
      selector: (data) => data.unit.toLowerCase(),
      cell: (data) => String(data.unit),
      sortable: true,
    },
    {
      name: "Quantity",
      width: "110px",
      selector: (data) => Number(data.quantity), // Ensure numeric sorting
      cell: (data) => String(data.quantity),
      sortable: true,
    },
    {
      name: "Start Price",
      width: "120px",
      selector: (data) => Number(data.startPrice), // Ensure numeric sorting
      cell: (data) => String(data.startPrice),
      sortable: true,
    },
    {
      name: "Inc./Dec.",
      width: "150px",
      selector: (data) => Number(data.increment), // Ensure numeric sorting
      cell: (data) => (
        <span>
          {data.increment} (
          {data.event.eventType === "FORWARD_AUCTION" ? "Inc." : "Dec."})
        </span>
      ),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (data) =>
        Number(parseFloat(data.quantity) * parseFloat(data.highestBid)), // Ensure numeric sorting
      cell: (data) =>
        String(parseFloat(data.quantity) * parseFloat(data.highestBid)),
      sortable: true,
    },
    {
      name: "Lot End Time",
      selector: ({ endDateAndTime }) =>
        endDateAndTime ? new Date(endDateAndTime).getTime() : 0, // Ensure date sorting
      cell: ({ endDateAndTime }) =>
        endDateAndTime
          ? String(
              moment(endDateAndTime).tz(getZone).format("DD-MM-YYYY hh:mm:ss")
            )
          : "-",
      sortable: true,
      width: "200px",
    },
    {
      name: "Lead Bid",
      selector: (data) => Number(data.highestBid), // Ensure numeric sorting
      cell: (data) => String(data.highestBid),
      sortable: true,
    },
    {
      name: "Lead Bidder",
      width: "150px",
      selector: (data) => data.highestBidder.toLowerCase(), // Ensure proper sorting
      cell: ({ highestBidder }) => (
        <Tooltip
          title={highestBidder}
          placement="top-start"
          TransitionComponent={Zoom}
        >
          <span className={classes.ellipsisText}>{highestBidder}</span>
        </Tooltip>
      ),
      sortable: true,
    },
  ];

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.auctionName,
      columns.lotNumber,
      columns.previewPeriod,
      columns.shortDescription,

      columns.unit,
      columns.quantity,
      columns.startPrice,
      `${columns.increment} (${
        columns.event.eventType === "FORWARD_AUCTION" ? "Inc." : "Dec."
      })`,
      parseInt(parseFloat(columns.quantity) * parseFloat(columns.highestBid)),
      moment(columns.endDateAndTime).tz(getZone).format("DD-MM-YYYY hh:mm:ss"),
      columns.highestBid,
      columns.highestBidder,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "bid-logs.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(auctions, columns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <div className={classes.section}>
      <div className="container">
        <div className={classes.Dashboard}>
          <div className={classes.dashboardCard}>
            <DashboardMenu />

            <div className={classes.dashboardCards}>
              <div className="AuctionTable">
                <Mount condition={connected === true}>
                  Status:{" "}
                  <span
                    style={{
                      color: "green",
                      fontWeight: "semi-bold",
                      fontSize: "0.9rem",
                    }}
                  >
                    Connected
                  </span>
                </Mount>
                <Mount condition={connected === false}>
                  Status:{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "semi-bold",
                      fontSize: "0.9rem",
                    }}
                  >
                    Disconnected
                  </span>
                </Mount>

                <Mount condition={userDetails.role === "ADMIN"}>
                  <Box style={{ marginTop: "20px" }}>
                    <Autocomplete
                      id="sellers"
                      size="small"
                      options={sellers}
                      disablePortal
                      onChange={(event, value) => {
                        selectSeller(value);
                      }}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField {...params} label="Sellers" />
                      )}
                    />
                  </Box>
                </Mount>
              </div>
            </div>

            <div className={classes.dashboardCards}>
              <div className="AuctionTable tableHead liveAuction">
                <h3>Live Lots List</h3>

                <DataTableExtensions
                  print={false}
                  columns={columns}
                  export={false}
                  exportHeaders={false}
                  data={auctions}
                >
                  <DataTable
                    pagination
                    noHeader={false}
                    highlightOnHover
                    className="DataTable"
                    defaultSortField="id"
                    defaultSortAsc={false}
                    actions={<ExportButton />}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Index);
