import React, { useState, useEffect } from "react";
import moment from "moment";
import { Box, Button, InputAdornment, Typography } from "@mui/material";
import Form from "react-bootstrap/Form";
import momentTimezone from "moment-timezone";
import { toast } from "react-toastify";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ImageUploading from "react-images-uploading";
import { BsUpload } from "react-icons/bs";

import uploadDocumentEvents from "../../../../../../api/uploadDocumentEvents";
import getEventItemDetail from "../../../../../../api/adminAPI/getEventItemDetails";
import getEventDetail from "../../../../../../api/getEventDetail";
import useStyles from "../../../../Create/Event/Items/item.style";
import updateEventItem from "../../../../../../api/adminAPI/updateEventItem";
import Loader from "../../../../../../common/Loader";

function EditItem({ eventEditId, eventItemId, SetHide, SetEventItemId }) {
  const eventId = eventEditId;
  const auctionId = eventId;
  const classes = useStyles();
  const eventItemIds = eventItemId;
  const maxNumber = 5;
  const [isLoading, setIsLoading] = useState(false);

  const [details, setDetails] = useState({});
  const [eventDetails, setEventDetails] = useState({});

  const [startDateNew, setStartDate] = useState();
  const [endDateNew, setEndDate] = useState();
  const [images, setImages] = useState("");
  const [imagesUploaded, setImagesuploaded] = useState([]);
  const [loadingEventItemDetails, setLoadingEventItemDetails] = useState(true);
  const [eventsDetail, updateEventsDetails] = useState({
    apiState: "loading",
    data: null,
  });

  const [eventUploadInfo, setEventUploadInfo] = useState({
    document: "",
  });
  const [eventItemInfo, setEventItemInfo] = useState({
    lotNumber: "",
    shortDescription: "",
    longDescription: "",
    quantity: "",
    unit: "",
    startPrice: "",
    startDateAndTime: "",
    increment: "",
    endDateAndTime: "",
    remarks: "",
    eventItemImages: "",
    currency: "",
  });

  useEffect(() => {
    fetchDetails();
    return () => {
      return false;
    };
  }, []);

  const fetchDetails = async () => {
    try {
      await fetchEventDetails();
      await fetchEventItemDetails();
    } finally {
      setLoadingEventItemDetails(false);
    }
  };

  // Get Event Details
  const fetchEventDetails = async () => {
    try {
      const payload = { auctionId: auctionId };
      const { data } = await getEventDetail(payload);
      setEventDetails(data);

      // if (data) {

      //   if(data) updateEventsDetails({ apiState: "success", list: data });

      // }
      // else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEventItemDetails = async () => {
    try {
      const { data } = await getEventItemDetail(eventEditId, eventItemIds);
      setDetails(data);

      if (data) {
        updateEventsDetails({ apiState: "success", data });
        const eventEditableDataFields = {
          lotNumber: data.lotNumber,
          shortDescription: data.shortDescription,
          longDescription: data.longDescription,
          quantity: data.quantity,
          unit: data.unit,
          startPrice: data.startPrice,
          startDateAndTime: data.startDateAndTime,
          increment: data.increment,
          endDateAndTime: data.endDateAndTime,
          timeInterval: data.timeInterval,
          maxMissedBids: data.maxMissedBids,
          remarks: data.remarks,
          eventItemImages: data.eventItemImagesUrl,
          currency: data.currency,
        };

        setEventItemInfo(eventEditableDataFields);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setEventItemInfo({
      ...eventItemInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleUnitChange = (event) => {
    if (event.target.value.match("^[a-zA-Z ]*$") != null) {
      setEventItemInfo({
        ...eventItemInfo,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleInputChange = (e) => {
    // if(e.target.value != "" || e.target.value.match(/^[0-9]\d*\.?\d*$/)) {
    //   setValue({ [e.target.name]: e.target.value })
    // }
    // setEventItemInfo({
    //   ...eventItemInfo,
    //   [e.target.name]: e.target.value,
    // });
    if (e.target === "Backspace" || e.target === "Delete") {
      eventItemInfo.shortDescription = " ";
    }
  };

  //Image Upload Functionality start

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    imageUpload(imageList);
  };
  const imageUpload = async (images) => {
    try {
      setIsLoading(true);
      images.map(async (image, index) => {
        eventUploadInfo.document = image["file"];
        const { data } = await uploadDocumentEvents(eventUploadInfo);
        setImagesuploaded([...imagesUploaded, data.documentId]);
        // setImagesuploaded(data);
        eventItemInfo.eventItemImages = data.documentId;
      });
      setIsLoading(false);
      toast("Item Image Uploaded Successfully!");
    } catch (error) {
      if (error.response) {
        error.response.data.error.map((errors, index) => {
          toast(errors);
          setIsLoading(false);
        });
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  //Image Upload Functionality End

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const isEnglishAuction = eventDetails.eventFormat === "ENGLISH_AUCTION";

      eventItemInfo.quantity = Number(eventItemInfo.quantity);
      eventItemInfo.startPrice = Number(eventItemInfo.startPrice);
      eventItemInfo.increment = Number(eventItemInfo.increment);
      // eventItemInfo.eventId = eventId;

      eventItemInfo.lastMinuteBid = "123";

      if (startDateNew != null || startDateNew != undefined) {
        let startDateMoment = moment(startDateNew.$d, "dd/MM/YYYY HH:mm:ss ZZ");
        eventItemInfo.startDateAndTime = startDateMoment.format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } else {
        eventItemInfo.startDateAndTime = eventsDetail.data.startDateAndTime;
      }
      if (isEnglishAuction) {
        if (endDateNew != null || endDateNew != undefined) {
          let endDateMoment = moment(endDateNew.$d, "dd/MM/YYYY HH:mm:ss ZZ");
          eventItemInfo.endDateAndTime = endDateMoment.format(
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          eventItemInfo.endDateAndTime = eventsDetail.data.endDateAndTime;
        }

        eventItemInfo.endDateAndTime = momentTimezone(
          eventItemInfo.endDateAndTime
        ).utc();
      } else {
        delete eventItemInfo.endDateAndTime;
      }

      if (eventItemInfo.currency !== "") {
        eventItemInfo.currency = eventItemInfo.currency;
      } else {
        eventItemInfo.currency = eventsDetail.data.currency;
      }
      eventItemInfo.eventItemImages = imagesUploaded;

      eventItemInfo.startDateAndTime = momentTimezone(
        eventItemInfo.startDateAndTime
      ).utc();

      if (eventItemInfo.lotNumber === "")
        eventItemInfo.lotNumber = eventsDetail.data.lotNumber;
      if (eventItemInfo.quantity === 0)
        eventItemInfo.quantity = Number(eventsDetail.data.quantity);
      if (eventItemInfo.startPrice === 0)
        eventItemInfo.startPrice = Number(eventsDetail.data.startPrice);
      if (eventItemInfo.increment === 0)
        eventItemInfo.increment = Number(eventsDetail.data.increment);
      if (eventItemInfo.currency === "")
        eventItemInfo.currency = eventsDetail.data.currency;
      //    if(eventItemInfo.endDateAndTime === '') eventItemInfo.endDateAndTime = eventsDetail.data.endDateAndTime;
      //    if(eventItemInfo.startDateAndTime === '') eventItemInfo.startDateAndTime = eventsDetail.data.startDateAndTime;
      if (eventItemInfo.shortDescription === "")
        eventItemInfo.shortDescription = eventsDetail.data.shortDescription;
      if (eventItemInfo.longDescription === "")
        eventItemInfo.longDescription = eventsDetail.data.longDescription;
      if (eventItemInfo.remarks === "")
        eventItemInfo.remarks = eventsDetail.data.remarks;
      if (eventItemInfo.unit === "")
        eventItemInfo.unit = eventsDetail.data.unit;
      if (eventItemInfo.eventItemImages.length === 0)
        eventItemInfo.eventItemImages = [];

      if (eventDetails.eventFormat === "JAPANESE_AUCTION") {
        eventItemInfo.maxMissedBids = eventItemInfo.maxMissedBids || 3;
      } else {
        eventItemInfo.maxMissedBids = null;
      }
      eventItemInfo.timeInterval = eventItemInfo.timeInterval;

      // delete payload.eventFormat;
      const { data } = await updateEventItem(
        eventItemInfo,
        eventEditId,
        eventItemIds
      );

      if (data) {
        toast("Event Item Update Successfully!");
        setTimeout(() => {
          //stepper.a11yProps(2);
          setIsLoading(false);
          SetHide(false);
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        toast(error.response.data.error);
        setIsLoading(false);
      } else if (error.request) {
        toast("Something Went Wrong!");
      } else {
        console.log("Error", error);
      }
      console.log(error);
    }

    //  setUserInfo({ email: "", firstname: "", lastname: "", phonenumber: "" , password: "", confirmpassword: ""});
  };

  if (loadingEventItemDetails) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <>
      {isLoading === true && <Loader isLoading={isLoading} />}
      <div className="editRules">
        <Box className={classes.accordanHeader}>
          <form onSubmit={handleSubmit}>
            <Box className={classes.accordanContent}>
              <div className={classes.formHead}>
                <h5 className="head">Item Detail</h5>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="mb-3">
                      <Form.Label className={classes.label}>
                        Lot Number
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        // placeholder="Auction Title"
                        name="lotNumber"
                        onChange={handleChange}
                        value={
                          eventItemInfo.lotNumber
                            ? eventItemInfo.lotNumber
                            : eventsDetail.data.lotNumber
                        }
                        required
                        isInvalid={
                          eventItemInfo.lotNumber === "" &&
                          eventsDetail.data.lotNumber === "" &&
                          true
                        }
                      />
                    </Form.Group>
                  </Box>
                  <Box className={classes.forms}>
                    <Form.Group className="mb-3">
                      <Form.Label className={classes.label}>
                        Short Description(Limit 50 characters)
                      </Form.Label>
                      <Form.Control
                        // {...{ onInputChange, value, onKeyDown }}
                        className={classes.input}
                        as="textarea"
                        name="shortDescription"
                        style={{ height: "24px" }}
                        onChange={handleChange}
                        onKeyDown={handleInputChange}
                        value={
                          eventItemInfo.shortDescription
                            ? eventItemInfo.shortDescription
                            : eventsDetail.data.shortDescription
                        }
                        maxLength={40}
                        isInvalid={
                          eventItemInfo.shortDescription === "" &&
                          eventsDetail.data.shortDescription === "" &&
                          true
                        }
                      />
                    </Form.Group>
                  </Box>
                  <Box className={classes.forms}>
                    <Form.Group className="mb-3">
                      <Form.Label className={classes.label}>
                        Remark (if any)
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="remarks"
                        onChange={handleChange}
                        value={
                          eventItemInfo.remarks
                            ? eventItemInfo.remarks
                            : eventsDetail.data.remarks
                        }
                      />
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Label className={classes.label}>
                      Long Description (Limit 250 characters)
                    </Form.Label>
                    <Form.Control
                      className={classes.input}
                      as="textarea"
                      name="longDescription"
                      onChange={handleChange}
                      value={
                        eventItemInfo.longDescription
                          ? eventItemInfo.longDescription
                          : eventsDetail.data.longDescription
                      }
                      style={{ height: "130px" }}
                      maxLength={250}
                      isInvalid={
                        eventItemInfo.longDescription === "" &&
                        eventsDetail.data.longDescription === "" &&
                        true
                      }
                    />
                  </Box>
                </div>
              </div>
              <div className={classes.formHead}>
                <h5 className="heading">Rate and Amount</h5>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="mb-3">
                      <Form.Label className={classes.label}>Qty</Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="number"
                        name="quantity"
                        min="1"
                        onChange={handleChange}
                        value={
                          eventItemInfo.quantity
                            ? eventItemInfo.quantity
                            : eventsDetail.data.quantity
                        }
                        isInvalid={
                          eventItemInfo.quantity === "" &&
                          eventsDetail.data.quantity === "" &&
                          true
                        }
                      />
                    </Form.Group>
                  </Box>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Label className={classes.label}>
                        Currency
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="currency"
                        placeholder="currency"
                        onChange={handleChange}
                        value={
                          eventItemInfo.currency
                            ? eventItemInfo.currency
                            : eventsDetail.data.currency
                        }
                        isInvalid={
                          eventItemInfo.currency === "" &&
                          eventsDetail.data.currency === "" &&
                          true
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Label className={classes.label}>
                          Start Price
                        </Form.Label>
                        <Form.Group className="mb-3">
                          <Form.Control
                            className={classes.input}
                            type="number"
                            name="startPrice"
                            min="0"
                            step={"0.01"}
                            onChange={handleChange}
                            value={
                              eventItemInfo.startPrice
                                ? eventItemInfo.startPrice
                                : eventsDetail.data.startPrice
                            }
                            isInvalid={
                              eventItemInfo.startPrice === "" &&
                              eventsDetail.data.startPrice === "" &&
                              true
                            }
                          />
                        </Form.Group>
                      </Box>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>Unit</Form.Label>
                    <Form.Control
                      className={classes.input}
                      type="text"
                      name="unit"
                      placeholder="Unit"
                      onChange={handleUnitChange}
                      value={
                        eventItemInfo.unit
                          ? eventItemInfo.unit
                          : eventsDetail.data.unit
                      }
                      isInvalid={
                        eventItemInfo.unit === "" &&
                        eventsDetail.data.unit === "" &&
                        true
                      }
                    />
                  </Form.Group>
                  <Box className={classes.forms}>
                    <Form.Group className="mb-3">
                      <Form.Label className={classes.label}>
                        {eventDetails.eventFormat === "DUTCH_AUCTION"
                          ? eventDetails.eventType === "REVERSE_AUCTION"
                            ? "Increment"
                            : "Decrement"
                          : eventDetails.eventType === "REVERSE_AUCTION"
                          ? "Decrement"
                          : "Increment"}
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="number"
                        name="increment"
                        step={"0.01"}
                        onChange={handleChange}
                        value={eventItemInfo.increment}
                        inputMode="decimal"
                        isInvalid={
                          eventItemInfo.increment === "" &&
                          eventsDetail.data.increment === "" &&
                          true
                        }
                        min={0.0}
                      />
                    </Form.Group>
                  </Box>
                </div>
              </div>
              <div className={classes.formHead}>
                <h5 className="time">Timing</h5>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Label className={classes.label}>
                    Start Date & Time{" "}
                    <span
                      style={{ color: "red", fontWeight: "800" }}
                    >{`>`}</span>{" "}
                    {moment(details.startDateAndTime).format(
                      "MMM-DD-YYYY HH:mm:ss"
                    )}
                  </Form.Label>
                  <br />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className="datePicker"
                      renderInput={(props) => <TextField {...props} />}
                      value={
                        startDateNew
                          ? startDateNew
                          : moment(eventsDetail.data.startDateAndTime)
                      }
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      isInvalid={startDateNew === "" && true}
                      minDate={new Date()}
                    />
                  </LocalizationProvider>
                </div>

                {eventDetails.eventFormat === "ENGLISH_AUCTION" && (
                  <div className="col-md-6 mob-margin">
                    <Form.Label className={classes.label}>
                      End Date & Time{" "}
                      <span
                        style={{ color: "red", fontWeight: "800" }}
                      >{`<`}</span>{" "}
                      {moment(eventDetails.bidEndDateTime).format(
                        "MMM-DD-YYYY HH:mm:ss"
                      )}
                    </Form.Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className="datePicker"
                        renderInput={(props) => <TextField {...props} />}
                        value={
                          endDateNew
                            ? endDateNew
                            : moment(eventsDetail.data.endDateAndTime)
                        }
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        isInvalid={endDateNew === "" && true}
                        minDate={new Date()}
                      />
                    </LocalizationProvider>
                  </div>
                )}
                {eventDetails.eventFormat === "JAPANESE_AUCTION" && (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <Form.Label className={classes.label}>
                      Max missed bids
                    </Form.Label>
                    <TextField
                      className={classes.input}
                      type="number"
                      size="small"
                      required={eventDetails.eventFormat === "JAPANESE_AUCTION"}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 0, // Removes border radius
                        },
                      }}
                      name="maxMissedBids"
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      onChange={handleChange}
                      value={eventItemInfo.maxMissedBids}
                      error={eventItemInfo.maxMissedBids === ""}
                      helperText={
                        eventItemInfo.maxMissedBids === ""
                          ? "This field is required"
                          : ""
                      }
                    />
                  </Box>
                )}
                {eventDetails.eventFormat !== "ENGLISH_AUCTION" && (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                      marginTop:
                        eventDetails.eventFormat === "JAPANESE_AUCTION"
                          ? ".8rem"
                          : "0rem",
                    }}
                  >
                    <Form.Label className={classes.label}>
                      Time interval
                    </Form.Label>
                    <TextField
                      className={classes.input}
                      type="number"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 0, // Removes border radius
                        },
                      }}
                      name="timeInterval"
                      InputProps={{
                        inputProps: { min: 1 },
                        endAdornment: (
                          <InputAdornment position="end">min.</InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                      value={eventItemInfo.timeInterval}
                      error={eventItemInfo.timeInterval === ""}
                      helperText={
                        eventItemInfo.timeInterval === ""
                          ? "This field is required"
                          : ""
                      }
                    />
                  </Box>
                )}
              </div>
              <div className={classes.formHead}>
                <h5 className="headings">Image and Document</h5>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className={classes.uploader}>
                    <p
                      className={classes.imageSize}
                      style={{ fontSize: "12px" }}
                    >
                      Dimension: 260px X 200px
                    </p>
                    <div className="row">
                      <p>Old Images</p>

                      {eventsDetail.data.eventItemImagesUrl &&
                        eventsDetail.data.eventItemImagesUrl.map(
                          (image, index) => (
                            <div className="col-md-3">
                              <div key={index} className="image-item">
                                <img
                                  src={image}
                                  alt=""
                                  width="100"
                                  className={classes.imagesStyle}
                                />
                                {/* <div className="image-item__btn-wrapper">
                                    <button onClick={() => onImageUpdate(index)}>
                                    Update
                                    </button>
                                    <button onClick={() => onImageRemove(index)}>
                                    Remove
                                    </button>
                                </div> */}
                              </div>
                            </div>
                          )
                        )}
                    </div>
                    <br />
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg", "jpeg", "png"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        // write your building UI
                        <>
                          <div className="upload__image-wrapper row">
                            <div className="col-md-4">
                              <Button
                                variant="contained"
                                component="label"
                                className={classes.upload}
                                sx={{
                                  display: "block",
                                  textAlign: "center",
                                  padding: "1.5em",
                                }}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <BsUpload className={classes.uploadIcon} />
                                <br />
                                <Box>
                                  <Typography
                                    sx={{
                                      color: "#000",
                                      fontSize: "12px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Choose Image to upload
                                  </Typography>
                                </Box>

                                {/* <input hidden accept="image/*" multiple type="file" /> */}
                              </Button>
                            </div>
                            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                            <div className="col-md-8">
                              <div className="row">
                                {imageList.map((image, index) => (
                                  <div className="col-md-3">
                                    <div key={index} className="image-item">
                                      <img
                                        src={image["data_url"]}
                                        alt=""
                                        width="100"
                                        className={classes.imagesStyle}
                                      />
                                      {/* <div className="image-item__btn-wrapper">
                                            <button onClick={() => onImageUpdate(index)}>
                                            Update
                                            </button>
                                            <button onClick={() => onImageRemove(index)}>
                                            Remove
                                            </button>
                                        </div> */}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {errors && (
                            <div>
                              {errors.maxNumber && (
                                <span>
                                  Number of selected images exceed maxNumber
                                </span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Your selected file type is not allow
                                </span>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
            </Box>
            <Box className={classes.saveNextBtn}>
              <Button
                className={classes.pre}
                onClick={() => {
                  SetHide(false);
                  SetEventItemId("");
                }}
              >
                Cancel
              </Button>

              <Button
                className={classes.next}
                style={{ marginRight: "2em" }}
                sx={{
                  marginLeft: "1em",
                  marginRight: { sm: "0em !important" },
                }}
                type="submit"
              >
                Update
              </Button>
              {/* <Button
                      className={classes.next}
                      onClick={() => {
                        navigate(routes.dashboard);
                      }}
                    >
                      Go To Dashboard
                    </Button> */}
            </Box>
          </form>
        </Box>
      </div>
    </>
  );
}

export default EditItem;
