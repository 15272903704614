import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";

import useStyles from "./styles";

const AuctionCard = ({
  status,
  eventImageUrl,
  eventId,
  auctionTitle,
  description,
  referenceNumber,
  auctioneerName,
  streetOne,
  streetTwo,
  city,
  province,
  postalCode,
  country,
  liveLots,
  totalLots,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className="col-lg-3 col-md-4 col-sm-12 mob-card">
      <div className={classes.AuctionCards}>
        <div className={classes.AuctionCard}>
          <p className={classes.firstText}>
            {status === "live" && (
              <span className={classes.liveButton}>Live Now</span>
            )}
            {status === "ended" && <span className={classes.ended}>Ended</span>}
            {status === "upcoming" && (
              <span className={classes.Upcoming}>Upcoming</span>
            )}
          </p>
          <img
            className={classes.productImg}
            alt="product"
            src={eventImageUrl}
            onClick={() => {
              navigate(`/auctions/${eventId}`);
            }}
          />
          <Box className={classes.auctionContent}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                height: "22px",
                overflow: "hidden",
                textOverflow: "ellispsis",
              }}
            >
              Event Name : {auctionTitle}
            </Typography>
            <Typography
              className={classes.fixedCard}
              sx={{ fontSize: "14px", fontWeight: "500" }}
            >
              Description : {description}
            </Typography>
            <Typography
              className={classes.fixedCard}
              sx={{ fontSize: "14px", fontWeight: "500" }}
            >
              Reference number : {referenceNumber}
            </Typography>
            <Box>
              <span className={classes.name}>
                Auctioner Name {auctioneerName}
              </span>
            </Box>
            <Box className={classes.loactionDetail}>
              <span className={classes.location}>
                Location:- {streetOne}, {streetTwo}, {city}, {province},{" "}
                {postalCode}, {country}
              </span>
            </Box>
            <Box>
              <span className={classes.lots}>
                {liveLots}/{totalLots} Lots
              </span>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AuctionCard;
