import React, { useState, useEffect } from "react";
import { Box, Button, InputAdornment, Typography } from "@mui/material";
import Form from "react-bootstrap/Form";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { BsUpload } from "react-icons/bs";
import momentTimezone from "moment-timezone";
import ImageUploading from "react-images-uploading";

import EditItem from "./EditItem";
import { createEventItemAPI } from "../../../../../../api/createEventItemAPI";
import uploadDocumentEvents from "../../../../../../api/uploadDocumentEvents";
import getEventDetails from "../../../../../../api/getEventDetail";
import Loader from "../../../../../../common/Loader";
import useStyles from "../../../../Create/Event/Items/item.style";

function EditIndexView({
  SetHide,
  eventEditId,
  edit,
  eventItemId,
  SetEventItemId,
}) {
  const eventId = eventEditId;
  const auctionId = eventId;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({});
  // const [hide, setHide] = useState(false);
  const [startDateNew, setStartDate] = useState();
  const [endDateNew, setEndDate] = useState();
  const [images, setImages] = useState("");
  const [imagesUploaded, setImagesuploaded] = useState([]);
  const maxNumber = 5;
  const [eventUploadInfo, setEventUploadInfo] = useState({
    document: "",
  });
  const [eventsDetail, updateEventsDetails] = useState({
    apiState: "loading",
    list: [],
  });

  const [eventItemInfo, setEventItemInfo] = useState({
    lotNumber: "",
    shortDescription: "",
    longDescription: "",
    quantity: "",
    unit: "",
    startPrice: "",
    startDateAndTime: "",
    increment: "",
    endDateAndTime: "",
    remarks: "",
    currency: "",
    maxMissedBids: null,
    timeInterval: null,
  });

  const handleChange = (event) => {
    if (event.target.name === "minimumAmount" && event.target.value < 0) {
      event.target.value = 0;
    }
    setEventItemInfo({
      ...eventItemInfo,
      [event.target.name]: event.target.value,
    });
  };

  //Image Upload Functionality start

  useEffect(() => {
    fetchEventDetails();
    // return () => {
    //   return false;
    // };
  }, []);

  const fetchEventDetails = async () => {
    try {
      const payload = { auctionId };
      const { data } = await getEventDetails(payload);
      setDetails(data);

      if (data) {
        updateEventsDetails({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    imageUpload(imageList);
  };
  const imageUpload = async (images) => {
    try {
      setIsLoading(true);
      images.map(async (image, index) => {
        eventUploadInfo.document = image["file"];
        const { data } = await uploadDocumentEvents(eventUploadInfo);
        setImagesuploaded([...imagesUploaded, data.documentId]);
      });
      setIsLoading(false);
      toast("Item Image Uploaded Successfully!");
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.error);
        error.response.data.error.map((errors, index) => {
          toast(errors);
          setIsLoading(false);
        });
      } else if (error.request) {
        toast("Something went wrong!");
      } else {
        console.log("Error", error);
      }
    }
  };

  const handleUnitChange = (event) => {
    if (event.target.value.match("^[a-zA-Z ]*$") != null) {
      setEventItemInfo({
        ...eventItemInfo,
        [event.target.name]: event.target.value,
      });
    }
  };

  //Image Upload Functionality End

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const isEnglishAuction =
        eventsDetail.list.eventFormat === "ENGLISH_AUCTION";

      eventItemInfo.quantity = Number(eventItemInfo.quantity);
      eventItemInfo.startPrice = Number(eventItemInfo.startPrice);
      eventItemInfo.increment = Number(eventItemInfo.increment);
      // eventItemInfo.eventId = eventId;

      eventItemInfo.lastMinuteBid = "123";
      if (startDateNew != null || startDateNew != undefined) {
        let startDateMoment = moment(startDateNew.$d, "dd/MM/YYYY HH:mm:ss ZZ");
        eventItemInfo.startDateAndTime = startDateMoment.format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } else {
        eventItemInfo.startDateAndTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      if (isEnglishAuction) {
        if (endDateNew != null || endDateNew != undefined) {
          let endDateMoment = moment(endDateNew.$d, "dd/MM/YYYY HH:mm:ss ZZ");
          eventItemInfo.endDateAndTime = endDateMoment.format(
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          eventItemInfo.endDateAndTime = moment().format("YYYY-MM-DD HH:mm:ss");
        }

        eventItemInfo.endDateAndTime = momentTimezone(
          eventItemInfo.endDateAndTime
        ).utc();
      } else {
        delete eventItemInfo.endDateAndTime;
      }

      if (eventItemInfo.currency !== "") {
        eventItemInfo.currency = eventItemInfo.currency;
      } else {
        eventItemInfo.currency = eventsDetail.list.currency;
      }
      eventItemInfo.eventItemImages = imagesUploaded;

      eventItemInfo.startDateAndTime = momentTimezone(
        eventItemInfo.startDateAndTime
      ).utc();

      const { data } = await createEventItemAPI(eventItemInfo, eventId);
      if (data) {
        toast("Event Item Create Successfully!");
        setTimeout(() => {
          setIsLoading(false);
          //stepper.a11yProps(2);
          SetHide(false);
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        toast(error.response.data.error);
        setIsLoading(false);
      } else if (error.request) {
        toast("Something Went Wrong!");
      } else {
        console.log("Error", error.message.code);
      }
      console.log(error);
      setIsLoading(false);
    }

    //  setUserInfo({ email: "", firstname: "", lastname: "", phonenumber: "" , password: "", confirmpassword: ""});
  };

  const shouldRenderMinimumAmountField = (eventFormat, eventType) => {
    if (eventFormat === "DUTCH_AUCTION" && eventType === "FORWARD_AUCTION")
      return true;
    if (eventFormat === "JAPANESE_AUCTION" && eventType === "REVERSE_AUCTION")
      return true;
    return false;
  };

  return (
    <>
      {isLoading === true && <Loader isLoading={isLoading} />}
      <div className="editRules">
        <Box className={classes.accordanHeader}>
          {edit === true && eventItemId != "" ? (
            <EditItem
              SetHide={SetHide}
              eventEditId={eventEditId}
              eventItemId={eventItemId}
              SetEventItemId={SetEventItemId}
            />
          ) : (
            <form onSubmit={handleSubmit}>
              <Box className={classes.accordanContent}>
                <div className={classes.formHead}>
                  <h5 className="head">Item Detail</h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="mb-3">
                        <Form.Label className={classes.label}>
                          Lot Number
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          // placeholder="Auction Title"
                          name="lotNumber"
                          onChange={handleChange}
                          value={eventItemInfo.lotNumber}
                          required
                          isInvalid={eventItemInfo.lotNumber === "" && true}
                        />
                      </Form.Group>
                    </Box>
                    <Box className={classes.forms}>
                      <Form.Group className="mb-3">
                        <Form.Label className={classes.label}>
                          Short Description(Limit 50 characters)
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          as="textarea"
                          name="shortDescription"
                          style={{ height: "24px" }}
                          onChange={handleChange}
                          value={eventItemInfo.shortDescription}
                          maxLength={40}
                          isInvalid={
                            eventItemInfo.shortDescription === "" && true
                          }
                        />
                      </Form.Group>
                    </Box>
                    <Box className={classes.forms}>
                      <Form.Group className="mb-3">
                        <Form.Label className={classes.label}>
                          Remark (if any)
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          name="remarks"
                          onChange={handleChange}
                          value={eventItemInfo.remarks}
                        />
                      </Form.Group>
                    </Box>
                  </div>
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Label className={classes.label}>
                        Long Description (Limit 250 characters)
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        as="textarea"
                        name="longDescription"
                        onChange={handleChange}
                        value={eventItemInfo.longDescription}
                        style={{ height: "130px" }}
                        maxLength={250}
                        isInvalid={eventItemInfo.longDescription === "" && true}
                      />
                    </Box>
                  </div>
                </div>
                <div className={classes.formHead}>
                  <h5 className="heading">Rate and Amount</h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Box className={classes.forms}>
                      <Form.Group className="mb-3">
                        <Form.Label className={classes.label}>Qty</Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="number"
                          name="quantity"
                          min="1"
                          onChange={handleChange}
                          value={eventItemInfo.quantity}
                          isInvalid={eventItemInfo.quantity === "" && true}
                        />
                      </Form.Group>
                    </Box>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Label className={classes.label}>
                          Currency
                        </Form.Label>
                        <Form.Control
                          className={classes.input}
                          type="text"
                          name="currency"
                          placeholder="currency"
                          onChange={handleChange}
                          value={
                            eventItemInfo.currency
                              ? eventItemInfo.currency
                              : eventsDetail.list.currency
                          }
                          isInvalid={
                            eventItemInfo.currency === "" &&
                            eventsDetail.list.currency === "" &&
                            true
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Label className={classes.label}>
                            Start Price
                          </Form.Label>
                          <Form.Group className="mb-3">
                            <Form.Control
                              className={classes.input}
                              type="number"
                              name="startPrice"
                              min="1"
                              step={"0.01"}
                              onChange={handleChange}
                              value={eventItemInfo.startPrice}
                              isInvalid={
                                eventItemInfo.startPrice === "" && true
                              }
                            />
                          </Form.Group>
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className={classes.label}>Unit</Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="unit"
                        placeholder="Unit"
                        onChange={handleUnitChange}
                        value={eventItemInfo.unit}
                        isInvalid={eventItemInfo.unit === "" && true}
                      />
                    </Form.Group>
                    <div className="row">
                      <div
                        className={`${
                          eventsDetail.list.eventFormat !== "ENGLISH_AUCTION" &&
                          eventsDetail.list.eventType === "REVERSE_AUCTION"
                            ? "col-md-6"
                            : "col-md-12"
                        } `}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label className={classes.label}>
                            {eventsDetail.list.eventFormat === "DUTCH_AUCTION"
                              ? eventsDetail.list.eventType ===
                                "REVERSE_AUCTION"
                                ? "Increment"
                                : "Decrement"
                              : eventsDetail.list.eventType ===
                                "REVERSE_AUCTION"
                              ? "Decrement"
                              : "Increment"}
                          </Form.Label>
                          <Form.Control
                            className={classes.input}
                            type="number"
                            name="increment"
                            onChange={handleChange}
                            value={eventItemInfo.increment}
                            isInvalid={eventItemInfo.increment === "" && true}
                            step={"0.01"}
                            inputMode="decimal"
                            min={0.0}
                          />
                        </Form.Group>
                      </div>

                      {shouldRenderMinimumAmountField(
                        eventsDetail.list.eventFormat,
                        eventsDetail.list.eventType
                      ) && (
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Form.Label className={classes.label}>
                            Minimum Amount
                          </Form.Label>
                          <TextField
                            className={classes.input}
                            type="number"
                            size="small"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: 0, // Removes border radius
                              },
                            }}
                            name="minimumAmount"
                            InputProps={{
                              inputProps: { min: 0 },
                              endAdornment: (
                                <InputAdornment position="end">
                                  {eventItemInfo.currency ||
                                    eventsDetail.list.currency}
                                </InputAdornment>
                              ),
                            }}
                            onChange={handleChange}
                            value={eventItemInfo.minimumAmount}
                            error={eventItemInfo.minimumAmount === ""}
                            helperText={
                              eventItemInfo.minimumAmount === ""
                                ? "This field is required"
                                : ""
                            }
                          />
                        </Box>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.formHead}>
                  <h5 className="time">Timing</h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Label className={classes.label}>
                      Start Date & Time {`>`}{" "}
                      {moment(details.bidStartDateTime).format(
                        "MMM-DD-YYYY HH:mm:ss"
                      )}
                    </Form.Label>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className="datePicker"
                        renderInput={(props) => <TextField {...props} />}
                        value={startDateNew}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        isInvalid={startDateNew === "" && true}
                        minDate={new Date()}
                      />
                    </LocalizationProvider>
                  </div>
                  {eventsDetail.list.eventFormat === "ENGLISH_AUCTION" && (
                    <div className="col-md-6 mob-margin">
                      <Form.Label className={classes.label}>
                        End Date & Time {`<`}{" "}
                        {moment(details.bidEndDateTime).format(
                          "MMM-DD-YYYY HH:mm:ss"
                        )}
                      </Form.Label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          className="datePicker"
                          renderInput={(props) => <TextField {...props} />}
                          value={endDateNew}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                          minDate={new Date()}
                          isInvalid={endDateNew === "" && true}
                        />
                      </LocalizationProvider>
                    </div>
                  )}
                  {eventsDetail.list.eventFormat === "JAPANESE_AUCTION" && (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <Form.Label className={classes.label}>
                        Max missed bids
                      </Form.Label>
                      <TextField
                        className={classes.input}
                        type="number"
                        size="small"
                        required={
                          eventsDetail.list.eventFormat === "JAPANESE_AUCTION"
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 0, // Removes border radius
                          },
                        }}
                        name="maxMissedBids"
                        InputProps={{
                          inputProps: { min: 1 },
                        }}
                        onChange={handleChange}
                        value={eventItemInfo.maxMissedBids}
                        error={eventItemInfo.maxMissedBids === ""}
                        helperText={
                          eventItemInfo.maxMissedBids === ""
                            ? "This field is required"
                            : ""
                        }
                      />
                    </Box>
                  )}
                </div>
                <div className="row mt-3">
                  {eventsDetail.list.eventFormat !== "ENGLISH_AUCTION" && (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <Form.Label className={classes.label}>
                        Time interval
                      </Form.Label>
                      <TextField
                        className={classes.input}
                        type="number"
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 0, // Removes border radius
                          },
                        }}
                        name="timeInterval"
                        InputProps={{
                          inputProps: { min: 1 },
                          endAdornment: (
                            <InputAdornment position="end">min.</InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        value={eventItemInfo.timeInterval}
                        error={eventItemInfo.timeInterval === ""}
                        helperText={
                          eventItemInfo.timeInterval === ""
                            ? "This field is required"
                            : ""
                        }
                      />
                    </Box>
                  )}
                </div>

                <div className={classes.formHead}>
                  <h5 className="headings">Image and Document</h5>
                  <h6 style={{ fontSize: "12px" }}>Dimension: 260px X 200px</h6>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className={classes.uploader}>
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        acceptType={["jpg", "jpeg", "png"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                          errors,
                        }) => (
                          // write your building UI
                          <>
                            <div className="upload__image-wrapper row">
                              <div className="col-md-4">
                                <Button
                                  variant="contained"
                                  component="label"
                                  className={classes.upload}
                                  sx={{
                                    display: "block",
                                    textAlign: "center",
                                    padding: "1.5em",
                                  }}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <BsUpload className={classes.uploadIcon} />
                                  <br />
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#000",
                                        fontSize: "12px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Choose Image to upload
                                    </Typography>
                                  </Box>

                                  {/* <input hidden accept="image/*" multiple type="file" /> */}
                                </Button>
                              </div>
                              {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                              <div className="col-md-8">
                                <div className="row">
                                  {imageList.map((image, index) => (
                                    <div className="col-md-2">
                                      <div key={index} className="image-item">
                                        <img
                                          src={image["data_url"]}
                                          alt=""
                                          width="100"
                                          className={classes.imagesStyle}
                                        />
                                        {/* <div className="image-item__btn-wrapper">
                                    <button onClick={() => onImageUpdate(index)}>
                                      Update
                                    </button>
                                    <button onClick={() => onImageRemove(index)}>
                                      Remove
                                    </button>
                                  </div> */}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {errors && (
                              <div>
                                {errors.maxNumber && (
                                  <span>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.acceptType && (
                                  <span>
                                    Your selected file type is not allow
                                  </span>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                </div>
              </Box>
              <Box className={classes.saveNextBtn}>
                <Button
                  className={classes.pre}
                  onClick={() => {
                    SetHide(false);
                    SetEventItemId("");
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className={classes.next}
                  sx={{ marginLeft: "1em" }}
                  type="submit"
                >
                  Update
                </Button>
              </Box>
            </form>
          )}
        </Box>
      </div>
    </>
  );
}

export default EditIndexView;
