import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { Button, Modal, Typography } from "@mui/material";
import useStyles from "../LotsTable/Lotstable.style";
import AddIcon from "@mui/icons-material/Add";
import { BiSearch } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import RemoveIcon from "@mui/icons-material/Remove";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import buyerAddLots from "../../../api/buyerAddLots";
import buyerRemoveLots from "../../../api/buyerRmoveLots";
import buyerViewLots from "../../../api/buyerViewLots";
import getViewByBuyerList from "../../../api/getViewByBuyerList";
import { useState, useEffect } from "react";
import moment from "moment";

export default function EditLotsTable(props) {
  const classes = useStyles();
  // const location = useLocation();
  const eventId = props.eventId;
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, updateClearSelectedRows] = useState(false);
  const [buyerLotsModal, toggleBuyerLotsModal] = useState(false);
  const check = props.type == "Add";
  const eventItemIds = props.data;

  const [tableEventsData, setTableEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [columnsEventBuyers, setColumnsEventBuyers] = useState([
    {
      name: "Buyer Name",
      selector: (row) => row.name?.toLowerCase() || "", // Ensures case-insensitive sorting
      cell: (data) => String(data.name),
      sortable: true,
    },
    {
      name: "Buyer Email",
      selector: (row) => row.email?.toLowerCase() || "", // Ensures case-insensitive sorting
      cell: (data) => String(data.email),
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: (row) => row.contactNumber || "", // Ensures numeric or string sorting
      cell: (data) => String(data.contactNumber),
      sortable: true,
    },
    {
      name: "Alt. Contact No.",
      selector: (row) => row.alternateContactNumber || "", // Ensures numeric or string sorting
      cell: (data) => String(data.alternateContactNumber),
      sortable: true,
    },
  ]);

  const eventBuyersList = async () => {
    try {
      const payload = { auctionId: eventId };
      const dataEventBuyers = await getViewByBuyerList(payload);

      if (dataEventBuyers) {
        const eventsBuyerList = dataEventBuyers.data.map((data) => {
          return {
            name: `${data.user.firstName} ${data.user.lastName}`,
            email: data.user.email,
            contactNumber: data.user.contactNumber,
            alternateContactNumber: data.user.alternateContactNumber,
            eventBuyerId: data.eventBuyerId,
            totalLots: data.totalLots,
          };
        });

        setTableEventsData(eventsBuyerList);
      } else throw new Error(dataEventBuyers);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventBuyersList();
    return () => {
      return false;
    };
  }, [eventId]);

  const handleSubmitSave = async (event) => {
    try {
      event.preventDefault();
      const paloadItemId = eventItemIds.map((ids, index) => {
        return ids.eventItemId;
      });

      const paloadBuyerId = selectedRows.map((ids, index) => {
        return ids.eventBuyerId;
      });

      const paylod = {
        eventBuyerIds: paloadBuyerId,
        eventItemIds: paloadItemId,
      };

      const { data } = await buyerAddLots(paylod, eventId);
      if (data) {
        setTimeout(() => {
          toast("Lots Added To Buyer Successfully!");
          props.modalHandleClose();
          props.eventItemsList();
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.errorMessage);
        error.response.data.errorMessage.map((errors, index) => {
          toast(errors.message);
          setIsLoading(false);
        });
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error.message.code);
        setIsLoading(false);
      }
    }
  };

  const handleSubmitRemove = async (event) => {
    try {
      event.preventDefault();
      const paloadItemId = eventItemIds.map((ids, index) => {
        return ids.eventItemId;
      });

      const paloadBuyerId = selectedRows.map((ids, index) => {
        return ids.eventBuyerId;
      });

      const paylod = {
        eventBuyerIds: paloadBuyerId,
        eventItemIds: paloadItemId,
      };

      const { data } = await buyerRemoveLots(paylod, eventId);
      if (data) {
        setTimeout(() => {
          toast("Lots Deleted To Buyer Successfully!");
          props.modalHandleClose();
          props.eventItemsList();
        }, 1500);
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast("Some Buyers already Bidded on lots!");
        setIsLoading(false);
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log(error.response.data.errorMessage);
        error.response.data.errorMessage.map((errors, index) => {
          toast(errors.message);
          setIsLoading(false);
        });
      }
    }
  };

  return (
    <Box
      sx={{ width: "100%", padding: "0px !important" }}
      className={classes.tableContainer}
    >
      <div
        className="email-container"
        style={{ background: "#2D55EB", paddingTop: "10px", height: "55px" }}
      >
        <p
          type="primary"
          sx={{ width: "fit-content !important" }}
          className="addlots"
        >
          {check ? "Add" : "Remove"}
        </p>
      </div>

      <DataTableExtensions
        print={false}
        export={false}
        exportHeaders={false}
        columns={columnsEventBuyers}
        data={tableEventsData}
        className="ronak"
      >
        <DataTable
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination={true}
          highlightOnHover
          export={false}
          exportHeaders={false}
          selectableRows={true}
          onSelectedRowsChange={(data) => {
            updateClearSelectedRows(false);
            setSelectedRows(data.selectedRows);
          }}
          clearSelectedRows={clearSelectedRows}
          className="DataTable"
        />
      </DataTableExtensions>
      {selectedRows.length > 0 && (
        <form onSubmit={check ? handleSubmitSave : handleSubmitRemove}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2D55EB",
              color: "#fff",
              borderRadius: "0px",
            }}
            type="submit"
          >
            {check ? "Save" : "Remove"}
          </Button>
        </form>
      )}
    </Box>
  );
}
