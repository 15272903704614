import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import useStyles from "../../dashAuction.style";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import buyerEventItemsDetailsAPI from "../../../../../api/adminAPI/buyerEventItemsDetailsAPI";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../../common";

function BuyerBidLog({
  DashEventDetail,
  buyerId,
  buyerEventId,
  buyerEventItemId,
}) {
  const classes = useStyles();

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const [tableBuyerEventItemsData, setTableBuyerEventItemsData] = useState([]);
  const [BuyerDataDetails, setBuyerDetails] = useState([]);

  const buyerEventItemsDetails = async (
    buyerEventId,
    buyerId,
    buyerEventItemId
  ) => {
    try {
      const { data } = await buyerEventItemsDetailsAPI(
        buyerEventId,
        buyerId,
        buyerEventItemId
      );

      if (data) {
        let id = 1;
        const buyersData = data.buyerEventLotWiseBids.map((dataBid, index) => {
          return {
            id: id++,
            bid: dataBid.amount,
            amount:
              parseFloat(dataBid.amount) *
              parseFloat(data.eventItemDetails.quantity),
            bidDate: moment(dataBid.createdAt)
              .tz(getZone)
              .format("DD-MM-YYYY hh:mm:ss"),
            lotNum: data.eventItemDetails.lotNumber,
          };
        });

        setTableBuyerEventItemsData(buyersData);
        setBuyerDetails(data);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    buyerEventItemsDetails(buyerEventId, buyerId, buyerEventItemId);
    return () => {
      return false;
    };
  }, [buyerEventId, buyerId, buyerEventItemId]);

  const nextColumns = [
    {
      name: "Sr.",
      selector: (row) => Number(row.id), // Ensure proper numeric sorting
      cell: ({ id }) => String(id),
      sortable: true,
    },
    {
      name: "Lot No.",
      selector: (row) => Number(row.lotNum), // Ensure proper numeric sorting
      cell: ({ lotNum }) => String(lotNum),
      sortable: true,
    },
    {
      name: "Bid Date & Time",
      selector: (row) => new Date(row.bidDate).getTime(), // Ensure proper date sorting
      cell: ({ bidDate }) => String(bidDate),
      sortable: true,
    },
    {
      name: "Bids",
      selector: (row) => Number(row.bid), // Ensure proper numeric sorting
      cell: ({ bid }) => String(bid),
      sortable: true,
    },
    {
      name: "Bid Amount",
      selector: (row) => Number(row.amount), // Ensure proper numeric sorting
      cell: ({ amount }) => Number(amount).toLocaleString(),
      sortable: true,
    },
  ];

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.lotNum,
      columns.bidDate,
      columns.bid,
      columns.amount,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "bid-log.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableBuyerEventItemsData, nextColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <>
      {tableBuyerEventItemsData.length === 0 ? (
        <div className="col-md-12">
          <div
            className={classes.detailCard}
            style={{ height: "300px", padding: "12%" }}
          >
            <h3>
              <center>
                Bid Log Is not Available!
                <br />
                <Button
                  sx={{ color: "#000", backgroundColor: "#0d6efd" }}
                  onClick={DashEventDetail}
                >
                  Click Here For Go Back
                </Button>
              </center>
            </h3>
          </div>
        </div>
      ) : (
        <>
          <div className="topDetail">
            <div className="row">
              <div className="col-md-12 m-auto">
                <div className={classes.BuyEventHeading}>
                  <h3>
                    <Button sx={{ color: "#000" }} onClick={DashEventDetail}>
                      <ArrowBackIcon />
                    </Button>
                    Buyer Bidlog
                  </h3>
                  <h3 className="mob">Event Details</h3>
                </div>
              </div>
              <div className="col-md-12">
                <div className={classes.detailCard}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className={classes.Detail}>
                            <p className={classes.SideLine}>
                              Name &nbsp; : &nbsp;{" "}
                              <span>
                                {BuyerDataDetails.buyerDetails?.firstName}{" "}
                                {BuyerDataDetails.buyerDetails?.lastName}
                              </span>
                            </p>
                            <p
                              className={`${classes.SideLine} ${classes.marginremove}`}
                            >
                              Company &nbsp; : &nbsp;{" "}
                              <span>
                                {BuyerDataDetails.buyerDetails?.firstName}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className={classes.Detail}>
                            <p>
                              Mobile No.&nbsp;: &nbsp;
                              <span>
                                {BuyerDataDetails.buyerDetails?.contactNumber}
                              </span>
                            </p>
                            <p className={classes.marginremove}>
                              Company&nbsp;:&nbsp;{" "}
                              <span>
                                {
                                  BuyerDataDetails.buyerDetails
                                    ?.alternateContactNumber
                                }
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className={classes.Detail}>
                            <p className={classes.SideLine}>
                              Event Name &nbsp; : &nbsp;{" "}
                              <span>
                                {BuyerDataDetails.eventDetails?.auctionTitle}
                              </span>
                            </p>
                            <p
                              className={`${classes.SideLine} ${classes.marginremove}`}
                            >
                              Auction Id &nbsp; : &nbsp;{" "}
                              <span>
                                {BuyerDataDetails.eventDetails?.eventNumber}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className={classes.Detail}>
                            <p>
                              Owner Of lot&nbsp;: &nbsp;
                              <span>
                                {BuyerDataDetails.eventDetails?.ownerOfLots}
                              </span>
                            </p>
                            <p className={classes.marginremove}>
                              Location&nbsp;:&nbsp;{" "}
                              <span>
                                {BuyerDataDetails.eventDetails?.country},{" "}
                                {BuyerDataDetails.eventDetails?.province}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className={classes.detailCard}>
                  <div className="row">
                    <div className="col-sm">
                      <div className={classes.Detail}>
                        <p className={classes.SideLine}>
                          Lot No. &nbsp; : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventItemDetails?.lotNumber}
                          </span>
                        </p>
                        <p
                          className={`${classes.SideLine} ${classes.marginremove}`}
                        >
                          Short Description &nbsp; : &nbsp;{" "}
                          <span>
                            {
                              BuyerDataDetails.eventItemDetails
                                ?.shortDescription
                            }
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className={classes.Detail}>
                        <p className={classes.SideLine}>
                          Start Date & Time &nbsp; : &nbsp;{" "}
                          <span>
                            {moment(
                              BuyerDataDetails.eventItemDetails
                                ?.startDateAndTime
                            )
                              .tz(getZone)
                              .format("DD/MM/YYYY hh:mm:ss")}
                          </span>
                        </p>
                        <p
                          className={`${classes.SideLine} ${classes.marginremove}`}
                        >
                          Bid ({BuyerDataDetails.eventDetails?.currency}) &nbsp;
                          : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventItemDetails?.highestBid}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className={classes.Detail}>
                        <p className={classes.SideLine}>
                          Buyer &nbsp; : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventItemDetails?.users.firstName}{" "}
                            {BuyerDataDetails.eventItemDetails?.users.lastName}
                          </span>
                        </p>
                        <p
                          className={`${classes.SideLine} ${classes.marginremove}`}
                        >
                          Position &nbsp; : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventItemDetails?.highestRank}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className={classes.Detail}>
                        <p className={classes.SideLine}>
                          Unit &nbsp; : &nbsp;{" "}
                          <span>{BuyerDataDetails.eventItemDetails?.unit}</span>
                        </p>
                        <p
                          className={`${classes.SideLine} ${classes.marginremove}`}
                        >
                          Quantity &nbsp; : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventItemDetails?.quantity}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className={classes.Detail}>
                        <p>
                          Start Price ({BuyerDataDetails.eventDetails?.currency}
                          ) &nbsp; : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventItemDetails?.startPrice}
                          </span>
                        </p>
                        <p className={classes.marginremove}>
                          Increment ({BuyerDataDetails.eventDetails?.currency})
                          &nbsp; : &nbsp;{" "}
                          <span>
                            {BuyerDataDetails.eventItemDetails?.increment}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="AuctionTable">
            <h3>Bid Log</h3>
            <DataTableExtensions
              print={false}
              export={false}
              exportHeaders={false}
              columns={nextColumns}
              data={tableBuyerEventItemsData}
            >
              <DataTable
                noHeader={false}
                defaultSortField="id"
                defaultSortAsc={false}
                pagination={false}
                highlightOnHover
                className="DataTable"
                actions={<ExportButton />}
              />
            </DataTableExtensions>
          </div>
        </>
      )}
    </>
  );
}

export default BuyerBidLog;
