import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import useStyles from "../dashAuction.style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import eventItemBidlogAPI from "../../../../api/adminAPI/eventItemBidlogAPI";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../common";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

function BidlogSecond({ AuctionView, eventId, eventItemId }) {
  const classes = useStyles();

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const [tableEventItemBidData, setTableEventItemBidData] = useState([]);

  const eventItemsBidDetails = async (eventId, eventItemId) => {
    try {
      const { data } = await eventItemBidlogAPI(eventId, eventItemId);

      if (data) {
        let id = 1;
        const eventsData = data.map((eventItem, index) => {
          return {
            id: id++,
            lotNumber: eventItem.lotNumber,
            buyerName: eventItem.buyerName,
            bidPrice: eventItem.amount,
            isAutobid: eventItem.isAutobid,
            isPrebid: eventItem.isPrebid,
            bidDateTime: moment(eventItem.biddingTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
          };
        });

        setTableEventItemBidData(eventsData);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventItemsBidDetails(eventId, eventItemId);
    return () => {
      return false;
    };
  }, [eventId, eventItemId]);

  const nextColumns = [
    {
      name: "Sr.",
      selector: (data) => Number(data.id) || 0,
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Lot No",
      selector: (data) => String(data.lotNumber || ""),
      cell: (data) => String(data.lotNumber),
      sortable: true,
    },
    {
      name: "Bidder",
      selector: (data) => String(data.buyerName || ""),
      cell: (data) => String(data.buyerName),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (data) => Number(data.bidPrice) || 0,
      cell: (data) => String(data.bidPrice),
      sortable: true,
    },
    {
      name: "Bid Type",
      cell: (data) =>
        data.isAutobid ? (
          <div style={{ color: "teal", fontWeight: "bold" }}>Proxy Bid</div>
        ) : data.isPrebid ? (
          <div style={{ color: "orange", fontWeight: "bold" }}>Pre-bid</div>
        ) : (
          <div style={{ color: "#4CAF50", fontWeight: "bold" }}>Manual</div>
        ),
      sortable: false,
    },
    {
      name: "Bid Date And Time",
      selector: (data) => new Date(data.bidDateTime).getTime() || 0,
      cell: (data) => String(data.bidDateTime),
      sortable: true,
    },
  ];

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.lotNumber,
      columns.buyerName,
      columns.bidPrice,
      columns.bidDateTime,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "bid-logs.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableEventItemBidData, nextColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <>
      <div className="AuctionTable" source="BUYER">
        <h3>
          <Button sx={{ color: "#000" }} onClick={AuctionView}>
            <ArrowBackIcon />
          </Button>
          Bid Log
        </h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={tableEventItemBidData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<ExportButton />}
          />
        </DataTableExtensions>
      </div>
    </>
  );
}

export default BidlogSecond;
