import React from "react";
import AuctionsList from "./AuctionsList";
import Banner from "./Banner";
import RulesAndsafety from "./RulesAndsafety";

function Home() {
  return (
    <React.Fragment>
      <Banner />
      <AuctionsList />
      <RulesAndsafety />
    </React.Fragment>
  );
}

export default Home;
