import { makeStyles } from "@mui/styles";
import colorSchemes from "../../../../constants/colorSchemes";

const useStyles = makeStyles((theme) => ({
  textHead: {
    fontWeight: "600 !important",
    [theme.breakpoints.down("md")]: {
      textAlign: "left !important",
    },
  },
  name: {
    color: colorSchemes.primaryColor,
    fontSize: "13px",
    height: "20px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  AuctionCard: { padding: "10px" },
  AuctionCards: {
    boxShadow: "0px 0px 16px #00000014",
    position: "relative",
    marginBottom: "2em",
  },
  productImg: { width: "100%", height: "11em", objectFit: "cover" },
  auctionContent: { padding: "10px" },
  loactionDetail: {
    // height: "8em",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  location: {
    fontSize: "14px",
    color: "#7E8184",
    height: "60px",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  lots: { fontSize: "14px", fontWeight: "500" },
  status: {
    color: colorSchemes.warningColor,
    boxShadow: "0px 0px 16px #00000014",
    padding: "8px 20px",
    textAlign: "left",
  },
  input: {
    borderRadius: "0px",
    color: "#3F4043",
    backgroundColor: "#F2F2F24D",
  },
  star: {
    position: "absolute",
    top: "18px",
    right: "18px",
    width: "30px",
    background: "#fff",
    borderRadius: "100%",
    height: "30px",
    textAlign: "center",
  },
  next: {
    color: "#fff !important",
    backgroundColor: "#2D55EB !important",
    minWidth: "100% !important",
    borderRadius: "0px !important",
  },
  fixedCard: {
    width: "100%",
    height: "22px",
    overflow: "hidden",
    textOverflow: "ellispsis",
  },
  time: { display: "flex" },
  firstText: {
    margin: "4px 0em 1em",
    color: "#3F4043",
    fontWeight: "bold",
    fontSize: "small !important",
    position: "absolute",
  },
  liveButton: {
    border: "1px solid #07AD00",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#07AD00",
    backgroundColor: "#E3FFD9",
    marginRight: "1em",
  },
  Upcoming: {
    border: "1px solid #DCD500",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#DCD500",
    backgroundColor: "mintcream",
    marginRight: "1em",
  },
  ended: {
    border: "1px solid #EB2D6F",
    padding: "0.1rem 1rem",
    fontWeight: "bold !important",
    color: "#EB2D6F",
    backgroundColor: "antiquewhite",
    marginRight: "1em",
  },
}));

export default useStyles;
