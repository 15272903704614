import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { AiFillEye } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { Button, Modal, Typography } from "@mui/material";
import useStyles from "./ViewLots.style";
import { AiOutlineEdit } from "react-icons/ai";
import { RiInboxArchiveLine } from "react-icons/ri";
import Form from "react-bootstrap/Form";
import BuyerTable from "../../../../../commonComponents/BuyerTable";
import LotsTable from "../../../../../commonComponents/LotsTable";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import getViewByBuyerList from "../../../../../../api/getViewByBuyerList";
import getViewByLotsList from "../../../../../../api/getViewByLotsList";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

export default function ViewLots() {
  const classes = useStyles();
  const location = useLocation();
  const eventId = location.state.data.eventId;
  const [tableEventsItemData, setTableEventsItemData] = useState([]);
  const [selectedRowsLots, setSelectedRowLots] = useState([]);
  const [clearSelectedRows, updateClearSelectedRows] = useState(false);

  const [columnsEventBuyers, setColumnsEventBuyers] = useState([
    {
      name: "Sr",
      selector: (data) => Number(data.id), // Ensure numeric sorting
      cell: (data) => String(data.id),
      sortable: true,
    },
    {
      name: "Lot No.",
      selector: (data) => Number(data.lotNumber), // Ensure numeric sorting
      cell: (data) => String(data.lotNumber),
      sortable: true,
    },
    {
      name: "Short Description",
      selector: (data) => String(data.shortDescription || ""), // Handle null values
      cell: (data) => String(data.shortDescription),
      sortable: true,
    },
    {
      name: "Location",
      selector: (data) => String(data.location || ""), // Handle null values
      cell: (data) => String(data.location),
      sortable: true,
    },
    {
      name: "Qty & Unit",
      selector: (data) => {
        const qty = parseFloat(data.QtyUnit) || 0; // Extract numeric part
        return qty;
      },
      cell: (data) => String(data.QtyUnit),
      sortable: true,
    },
    {
      name: "Start Date & Time",
      selector: (data) => new Date(data.startDate).getTime(), // Convert to timestamp
      cell: (data) => String(data.startDate),
      sortable: true,
    },
    {
      name: "End Date & Time",
      selector: (data) => new Date(data.EndDate).getTime(), // Convert to timestamp
      cell: (data) => String(data.EndDate),
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (data) => Number(data.totalBuyers) || 0, // Ensure numeric sorting
      cell: (data) => (
        <div>
          <RiInboxArchiveLine className={classes.lots} />{" "}
          {String(data.totalBuyers)}
        </div>
      ),
      sortable: true, // Enable sorting by total buyers
      export: false,
    },
  ]);

  const eventItemsList = async () => {
    try {
      const payload = { auctionId: eventId };

      const dataEventItems = await getViewByLotsList(payload);

      if (dataEventItems) {
        let id = 1;
        const eventsBuyerList = dataEventItems.data.map((data) => {
          return {
            id: id++,
            eventItemId: data.eventItemId,
            lotNumber: data.lotNumber,
            location: data.location,
            shortDescription: data.shortDescription,
            QtyUnit: `${data.quantity} ${data.unit}`,
            startDate: moment(data.startDateAndTime).format(
              "DD-MM-YYYY hh:mm:ss"
            ),
            EndDate: data.endDateAndTime
              ? moment(data.endDateAndTime).format("DD-MM-YYYY hh:mm:ss")
              : "-",
            totalBuyers: data.totalBuyers,
          };
        });

        setTableEventsItemData(eventsBuyerList);
      } else throw new Error(dataEventItems);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventItemsList();
    return () => {
      return false;
    };
  }, [eventId]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    //   border: "1px solid #989898",
    boxShadow: "none",
    p: 4,
  };

  const [modalOpen, setModalOpen] = useState({});
  const handleOpen = (text) =>
    setModalOpen({
      visible: true,
      type: text,
    });
  const handleClose = (text) =>
    setModalOpen({
      visible: false,
      type: text,
    });

  const handleSelectedRowsChange = (data) => {
    setSelectedRowLots(data.selectedRows);
  };
  return (
    <Box sx={{ width: "100%", padding: "0px !important" }}>
      {selectedRowsLots.length > 0 && (
        <div
          className="email-container"
          style={{ background: "#2D55EB", paddingTop: "10px", height: "55px" }}
        >
          <Button
            type="primary"
            className="addlots"
            onClick={() => {
              handleOpen("Add");
            }}
          >
            Add Buyer
          </Button>
          <Button
            type="primary"
            className="addlots"
            sx={{ color: "red !important" }}
            onClick={() => {
              handleOpen("Remove");
            }}
          >
            Remove Buyer
          </Button>
        </div>
      )}
      <DataTableExtensions
        print={false}
        export={false}
        exportHeaders={false}
        columns={columnsEventBuyers}
        data={tableEventsItemData}
        onSelectedRowsChange={handleSelectedRowsChange}
      >
        <DataTable
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          export={false}
          exportHeaders={false}
          selectableRows={true}
          onSelectedRowsChange={handleSelectedRowsChange}
          className="DataTable"
        />
      </DataTableExtensions>

      <Modal
        open={modalOpen.visible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            className="hoverClose"
            onClick={handleClose}
            sx={{
              backgroundColor: "rgb(45, 85, 235)",
              color: "#fff",
              marginBottom: "1em",
            }}
          >
            <CloseIcon />
          </Button>
          <LotsTable
            type={modalOpen.type}
            modalHandleClose={handleClose}
            eventItemsList={eventItemsList}
            data={[...selectedRowsLots]}
            setSelectedRowLots={setSelectedRowLots}
          />
        </Box>
      </Modal>
    </Box>
  );
}
