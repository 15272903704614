import React, { useState } from "react";
import DashboardMenu from "../../commonComponents/DashboardMenu";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import useStyles from "../DashboardAuction/dashAuction.style";
import sellerListAPI from "../../../api/adminAPI/sellerListAPI";
import AuctionView from "../DashboardAuction/AuctionView";
import BidlogFirst from "../DashboardAuction/BidlogFirst";
import BidlogSecond from "../DashboardAuction/BidogSecond";
import DashBuyerDetail from "../DashboardAuction/BidlogFirst/DashBuyerDetail";
import DashEventDetail from "../DashboardAuction/BidlogFirst/DashEventDetail";
import BuyerBidLog from "../DashboardAuction/BidlogFirst/BuyerBidLog";
import EditEvent from "../DashboardAuction/EditDashEvent/EditEvent";
import AuctionTable from "../DashboardAuction/AuctionTable";
import EditTabEvent from "../DashboardAuction/EditDashEvent/EditTabEvent";
import AuctionViewFirst from "../DashboardAuction/BidlogFirst/AuctionViewFirst";
import AuctionSummary from "../DashboardAuction/BidlogFirst/AuctionSummary";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sellerDeleteAPI from "../../../api/adminAPI/sellerDeleteAPI";
import sellerStatusUpdateAPI from "../../../api/adminAPI/sellerUpdateStatusAPI";
import sellerUpdateRightsAPI from "../../../api/adminAPI/sellerUpdateRightsAPI";
import Form from "react-bootstrap/Form";
import { isEmpty, omit } from "lodash";
import getCountries from "../../../api/getContriesAPI";
import getStateAPI from "../../../api/getStateAPI";
import { getToken, removeToken, getUserData } from "../../../common";
import getSellerDetails from "../../../api/adminAPI/getSellerDetails";
import updateSellerAPI from "../../../api/adminAPI/updateSellerAPI";
import { Navigate } from "react-router-dom";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

function DashSeller() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changeView, SetChangeView] = useState(false);
  const [eventId, SetEventId] = useState("");
  const [buyerId, SetBuyerId] = useState("");
  const [status, SetStatus] = useState("");
  const [buyerEventId, SetBuyerEventId] = useState("");
  const [buyerEventItemId, SetBuyerEvenItemtId] = useState("");
  const [eventItemId, SetEvenItemtId] = useState("");
  const [auctionRights, setAuctionRights] = useState("");

  //const open = Boolean(anchorEl);

  const [tableSellers, setTableSellers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const sellerList = async () => {
    try {
      const { data } = await sellerListAPI();

      // setRightsId(data)

      if (data) {
        let id = 1;
        const sellersData = data.map((list, index) => {
          return {
            id: id++,
            companyName: list.companyName,
            businessType: list.businessType,
            title: list.title,
            userName: `${list.firstName} ${list.lastName}`,
            email: list.email,
            contactNumber: list.contactNumber,
            userId: list.userId,
            alternateContactNumber: list.alternateContactNumber,
            totalEventBuyers: list.totalEventBuyers,
            totalEventItems: list.totalEventItems,
            totalEvents: list.totalEvents,
            totalParticipants: list.totalParticipants,
            status: list.status,
            auctionStatus: list.auctionStatus,
            location: `${list.country} ${list.state}`,
          };
        });
        setTableSellers(sellersData);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    sellerList();
    return () => {
      return false;
    };
  }, [eventId]);

  const handleClick = (event, userId, status, auctionStatus) => {
    //console.log(eventId)
    SetBuyerId(userId);
    setSellerRightsId(userId);
    SetStatus(status);
    setAuctionRights(auctionStatus);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    SetBuyerId("");
    SetStatus("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const columns = [
    {
      name: "Sr No",
      selector: (data) => data.id,
      cell: (data) => String(data.id),
      width: "100px",
      sortable: true,
    },
    {
      name: "Title",
      selector: (data) => data.title,
      cell: (data) => String(data.title),
      sortable: true,
    },
    {
      name: "Seller name",
      selector: (data) => data.userName,
      cell: (data) => String(data.userName),
      sortable: true,
      width: "150px",
    },
    {
      name: "Company",
      selector: (data) => data.companyName,
      cell: (data) => String(data.companyName),
      sortable: true,
      width: "130px",
    },
    {
      name: "Mobile number",
      selector: (data) => data.contactNumber,
      cell: (data) => String(data.contactNumber),
      sortable: true,
      width: "140px",
    },
    {
      name: "Alt. mobile Number",
      selector: (data) => data.alternateContactNumber || "", // Handle null values
      cell: (data) => String(data.alternateContactNumber || ""),
      sortable: true,
      width: "180px",
    },
    {
      name: "Email",
      selector: (data) => data.email.toLowerCase(), // Sorting emails case-insensitively
      cell: (data) => String(data.email),
      sortable: true,
    },
    {
      name: "Business type",
      selector: (data) => data.businessType,
      cell: (data) => String(data.businessType),
      sortable: true,
      width: "150px",
    },
    {
      name: "Total event buyers",
      selector: (data) => parseInt(data.totalEventBuyers, 10), // Ensure numeric sorting
      width: "180px",
      cell: (data) => String(data.totalEventBuyers),
      sortable: true,
    },
    {
      name: "Total event items",
      selector: (data) => parseInt(data.totalEventItems, 10),
      width: "150px",
      cell: (data) => String(data.totalEventItems),
      sortable: true,
    },
    {
      name: "Total events",
      selector: (data) => parseInt(data.totalEvents, 10),
      width: "150px",
      cell: (data) => String(data.totalEvents),
      sortable: true,
    },
    {
      name: "Total participants",
      selector: (data) => parseInt(data.totalParticipants, 10),
      width: "180px",
      cell: (data) => String(data.totalParticipants),
      sortable: true,
    },
    {
      name: "Status",
      selector: (data) => data.status,
      width: "150px",
      cell: (data) => String(data.status),
      sortable: true,
    },
    {
      name: "Auction create rights",
      selector: (data) => data.auctionStatus,
      width: "180px",
      cell: (data) => String(data.auctionStatus),
      sortable: true,
    },
    {
      name: "Action",
      width: "150px",
      sortable: false, // Actions are not sortable
      cell: (data) => (
        <div>
          <Button
            aria-describedby={id}
            variant="contained"
            onClick={(event) =>
              handleClick(event, data.userId, data.status, data.auctionStatus)
            }
          >
            <MoreVertIcon />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2, display: "grid" }}>
              <div
                className={classes.viewBtn}
                onClick={() => {
                  SetChangeView("AuctionTable");
                  SetBuyerId(buyerId);
                }}
              >
                <RemoveRedEyeIcon
                  style={{ color: "#2D55EB", paddingRight: "5px" }}
                />
                Report
              </div>
              <span className={classes.viewBtn} onClick={handleOpen}>
                <EditIcon style={{ color: "#2D55EB", paddingRight: "5px" }} />
                Edit
              </span>
              <span
                className={classes.viewBtn}
                onClick={() => handleClickOpen(buyerId)}
              >
                <DeleteIcon style={{ color: "red", paddingRight: "5px" }} />
                Delete
              </span>
              {status === "ACTIVE" ? (
                <span
                  className={classes.viewBtn}
                  onClick={() => handleClickStatusOpen(buyerId, status)}
                >
                  <CancelIcon style={{ color: "red", paddingRight: "5px" }} />
                  Inactive
                </span>
              ) : (
                <span
                  className={classes.viewBtn}
                  onClick={() => handleClickStatusOpen(buyerId, status)}
                >
                  <CheckIcon style={{ color: "green", paddingRight: "5px" }} />
                  Active
                </span>
              )}

              <span
                className={classes.viewBtn}
                onClick={() =>
                  handleClickRightsOpen(sellerRightsId, auctionRights)
                }
              >
                <EditIcon style={{ color: "#2D55EB", paddingRight: "5px" }} />
                Auction Rights
              </span>
            </Typography>
          </Popover>
        </div>
      ),
    },
  ];

  const [openDilogue, setOpenDilogue] = React.useState(false);
  // const [openRightsDilogue, setOpenRightsDilogue] = React.useState(false);

  const [deleteSellerId, setDeleteSellerId] = React.useState("");

  const updateDeleteSeller = async () => {
    try {
      const { data } = await sellerDeleteAPI(deleteSellerId);

      toast("Seller Deleted Successfully!");
      setTimeout(() => {
        sellerList();
        setOpenDilogue(false);
        setAnchorEl(null);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = (eventId) => {
    setOpenDilogue(true);
    setDeleteSellerId(eventId);
  };

  const handleCloseDilogue = () => {
    setOpenDilogue(false);
    setAnchorEl(null);
  };

  const [openStatusDilogue, setOpenStatusDilogue] = React.useState(false);
  const [openRightsDilogue, setOpenRightsDilogue] = React.useState(false);

  const handleClickStatusOpen = (eventId, status) => {
    setOpenStatusDilogue(true);
    setstatusSellerId(eventId);
    setStatusSeller(status);
  };

  const handleClickRightsOpen = (sellerRightsId, auctionStatus) => {
    setOpenRightsDilogue(true);
    setSellerRightsId(sellerRightsId);
    console.log("setSellerRightsId", sellerRightsId);
    setSellerRights(auctionStatus);
  };

  const handleCloseStatusDilogue = () => {
    setOpenStatusDilogue(false);
    setAnchorEl(null);
  };

  const handleCloseRightsDilogue = () => {
    setOpenRightsDilogue(false);
    setAnchorEl(null);
  };

  const [statusSellerId, setstatusSellerId] = React.useState("");
  const [sellerRightsId, setSellerRightsId] = React.useState("");
  const [statusSeller, setStatusSeller] = React.useState("");
  const [sellerRights, setSellerRights] = React.useState("");

  const updateStatusSeller = async () => {
    try {
      let statusFlag = "ACTIVE";
      if (statusSeller === "ACTIVE") {
        statusFlag = "INACTIVE";
      }
      const payload1 = { status: statusFlag };

      const data = await sellerStatusUpdateAPI(payload1, statusSellerId);
      console.clear();
      toast("Seller Status Updated!");
      setTimeout(() => {
        sellerList();
        setOpenStatusDilogue(false);
        setAnchorEl(null);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const updateRightsSeller = async () => {
    try {
      const auctionRights = {};
      if (sellerRights === "YES") {
        auctionRights.auctionStatus = "NO";
      } else {
        auctionRights.auctionStatus = "YES";
      }

      await sellerUpdateRightsAPI(auctionRights, sellerRightsId);

      toast("Seller Rights Updated!");
      setTimeout(() => {
        sellerList();
        setOpenRightsDilogue(false);
        setAnchorEl(null);
      }, 1000);
    } catch (error) {
      toast(error.message);
      setOpenRightsDilogue(false);
      setAnchorEl(null);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    //   border: "1px solid #989898",
    boxShadow: "none",
    p: 4,
  };

  const [modalOpen, setModalOpen] = useState({});
  const handleOpen = (text) =>
    setModalOpen({
      visible: true,
      type: text,
    });
  const handleCloseModal = (text) =>
    setModalOpen({
      visible: false,
      type: text,
    });

  const [editProfile, SeteditProfile] = useState(false);
  const [typeBuySel, SetTypeBuySel] = useState(false);

  const userData = getUserData() ?? {};

  const [profileDetail, setProfileDetail] = useState({
    apiState: "loading",
    list: "",
  });

  const sellerId = buyerId;
  const rightsId = buyerId;

  const getProfileData = async (sellerId) => {
    try {
      const { data } = await getSellerDetails(sellerId);

      if (data) setProfileDetail({ apiState: "success", list: data });
      else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getProfileData(sellerId);
    return () => {
      return false;
    };
  }, [sellerId]);

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // Validation Form

  const [errors, setErrors] = useState({});

  const validate = (event, name, value) => {
    //A function to validate each input values
    const re = /^[A-Za-z ]+$/;
    switch (name) {
      case "firstName":
        if (!re.test(value)) {
          // we will set the error firstName

          setErrors({
            ...errors,
            firstName: "First Name should contains only Letters",
          });
        } else {
          // set the error firstName empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "firstName");
          setErrors(newObj);
        }
        break;

      case "lastName":
        if (!re.test(value)) {
          // we will set the error lastName

          setErrors({
            ...errors,
            lastName: "Last Name should contains only Letters",
          });
        } else {
          // set the error lastName empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "lastName");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const [userInfoSeller, setUserInfoSeller] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    alternateContactNumber: "",
    bi_tin_ein: "",
    businessType: "",
    companyName: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
    streetOne: "",
    streetTwo: "",
    title: "",
  });

  const handleChangeSeller = (event) => {
    event.persist();
    validate(event, event.target.name, event.target.value);
    if (event.target.name === "country") stateData(event.target.value);
    setUserInfoSeller({
      ...userInfoSeller,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitSeller = async (event) => {
    try {
      event.preventDefault();

      if (!isEmpty(errors)) {
        toast("Please fill correct value on all fields. !");
      } else {
        userInfoSeller.firstName = userInfoSeller.firstName
          ? userInfoSeller.firstName
          : profileDetail.list.firstName;
        userInfoSeller.lastName = userInfoSeller.lastName
          ? userInfoSeller.lastName
          : profileDetail.list.lastName;
        userInfoSeller.alternateContactNumber =
          userInfoSeller.alternateContactNumber
            ? userInfoSeller.alternateContactNumber
            : profileDetail.list.alternateContactNumber;
        userInfoSeller.contactNumber = userInfoSeller.contactNumber
          ? userInfoSeller.contactNumber
          : profileDetail.list.contactNumber;
        userInfoSeller.email = userInfoSeller.email
          ? userInfoSeller.email
          : profileDetail.list.email;

        userInfoSeller.companyName = userInfoSeller.companyName
          ? userInfoSeller.companyName
          : profileDetail.list.companyName;
        userInfoSeller.bi_tin_ein = userInfoSeller.bi_tin_ein
          ? userInfoSeller.bi_tin_ein
          : profileDetail.list.bi_tin_ein;
        userInfoSeller.businessType = userInfoSeller.businessType
          ? userInfoSeller.businessType
          : profileDetail.list.businessType;
        userInfoSeller.city = userInfoSeller.city
          ? userInfoSeller.city
          : profileDetail.list.city;
        userInfoSeller.country = userInfoSeller.country
          ? userInfoSeller.country
          : profileDetail.list.country;
        userInfoSeller.postalCode = userInfoSeller.postalCode
          ? userInfoSeller.postalCode
          : profileDetail.list.postalCode;
        userInfoSeller.state = userInfoSeller.state
          ? userInfoSeller.state
          : profileDetail.list.state;
        userInfoSeller.streetOne = userInfoSeller.streetOne
          ? userInfoSeller.streetOne
          : profileDetail.list.streetOne;
        userInfoSeller.streetTwo = userInfoSeller.streetTwo
          ? userInfoSeller.streetTwo
          : profileDetail.list.streetTwo;
        userInfoSeller.title = userInfoSeller.title
          ? userInfoSeller.title
          : profileDetail.list.title;

        const { data } = await updateSellerAPI(userInfoSeller, sellerId);

        if (data) {
          toast("Profile Updated Successfully. !");
          sellerList();
          setOpenDilogue(false);
          setAnchorEl(null);
          setModalOpen({
            visible: false,
          });
          setUserInfoSeller({});
        }
      }
    } catch (error) {
      toast(error.response.data.error);
      setIsLoading(false);
      if (error.response.status == 500) {
        toast("Network error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error.message.code);
        setIsLoading(false);
      }
    }
  };

  //Get Countries Nad States

  const [countriesList, updateCountriesList] = useState({
    apiState: "loading",
    list: [],
  });

  const [stateList, updateStateList] = useState({
    apiState: "loading",
    list: [],
  });

  const countryList = async () => {
    try {
      const { data } = await getCountries();

      if (data) {
        if (data) updateCountriesList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    countryList();
    return () => {
      return false;
    };
  }, []);

  const stateData = async (countryId) => {
    try {
      const { data } = await getStateAPI(countryId);

      if (data) {
        if (data) updateStateList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    stateData();
    return () => {
      return false;
    };
  }, []);

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.title,
      columns.userName,
      columns.companyName,
      columns.contactNumber,
      columns.alternateContactNumber,
      columns.email,
      columns.businessType,
      parseFloat(Number(columns.totalEventBuyers)),
      parseFloat(Number(columns.totalEventItems)),
      parseFloat(Number(columns.totalEvents)),
      parseInt(Number(columns.totalParticipants)),
      columns.status,
      columns.auctionStatus,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "sellers-list.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableSellers, columns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  const renderComponent = () => {
    switch (changeView) {
      case "AuctionView":
        return (
          <AuctionView
            Bidlog={() => {
              SetChangeView("AuctionBid");
            }}
            BidlogSecond={(eventItemId) => {
              SetChangeView("BidlogSecond");
              SetEvenItemtId(eventItemId);
            }}
            AuctionTable={() => {
              SetChangeView("AuctionTable");
            }}
            eventId={eventId}
          />
        );
      case "AuctionBid":
        return (
          <BidlogFirst
            DashBuyerDetail={(buyerId) => {
              SetChangeView("DashBuyerDetail");
              SetBuyerId(buyerId);
            }}
            AuctionView={() => {
              SetChangeView("AuctionView");
            }}
            eventId={eventId}
            AuctionViewFirst={() => {
              SetChangeView("AuctionViewFirst");
            }}
            AuctionSummary={() => {
              SetChangeView("AuctionSummary");
            }}
          />
        );
      case "BidlogSecond":
        return (
          <BidlogSecond
            AuctionView={() => {
              SetChangeView("AuctionView");
            }}
            eventItemId={eventItemId}
            eventId={eventId}
          />
        );
      case "DashBuyerDetail":
        return (
          <DashBuyerDetail
            DashEventDetail={(buyerId, buyerEventId) => {
              SetChangeView("DashEventDetail");
              SetBuyerEventId(buyerEventId);
            }}
            BidlogFirst={() => {
              SetChangeView("AuctionBid");
            }}
            buyerId={buyerId}
          />
        );

      case "DashEventDetail":
        return (
          <DashEventDetail
            BuyerBidLog={(eventItemId) => {
              SetChangeView("BuyerBidLog");
              SetBuyerEvenItemtId(eventItemId);
            }}
            DashBuyerDetail={(buyerId) => {
              SetChangeView("DashBuyerDetail");
              SetBuyerId(buyerId);
            }}
            buyerId={buyerId}
            buyerEventId={buyerEventId}
          />
        );
      case "BuyerBidLog":
        return (
          <BuyerBidLog
            DashEventDetail={() => {
              SetChangeView("DashEventDetail");
            }}
            buyerId={buyerId}
            buyerEventId={buyerEventId}
            buyerEventItemId={buyerEventItemId}
          />
        );
      case "EditTabEvent":
        return <EditTabEvent />;

      case "EditEvent":
        return (
          <EditEvent
            AuctionTable={() => {
              SetChangeView("AuctionTable");
            }}
            EditTabEvent={() => {
              SetChangeView("EditTabEvent");
            }}
          />
        );
      case "AuctionTable":
        return (
          <AuctionTable
            onView={(eventId) => {
              SetChangeView("AuctionView");
              SetEventId(eventId);
            }}
            onEdit={() => {
              SetChangeView("EditEvent");
            }}
            buyerId={buyerId}
          />
        );
      case "AuctionViewFirst":
        return (
          <AuctionViewFirst
            BidlogFirst={() => {
              SetChangeView("AuctionBid");
            }}
          />
        );
      case "AuctionSummary":
        return (
          <AuctionSummary
            BidlogFirst={() => {
              SetChangeView("AuctionBid");
            }}
          />
        );
      default:
        return (
          <div className="AuctionTable">
            {userData.role != "SELLER" && userData.role != "BUYER" ? (
              <>
                <h3>Seller List</h3>
                <DataTableExtensions
                  print={false}
                  export={false}
                  exportHeaders={false}
                  columns={columns}
                  data={tableSellers}
                >
                  <DataTable
                    noHeader={false}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    className="DataTable"
                    actions={<ExportButton />}
                  />
                </DataTableExtensions>
              </>
            ) : (
              <Navigate to={{ pathname: "/dashboard" }} />
            )}

            {/* Dialog box for delete seller */}
            <Dialog
              open={openDilogue}
              onClose={handleCloseDilogue}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Seller ?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete Seller ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDilogue}>Cancle</Button>
                <Button onClick={updateDeleteSeller} autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            {/* dialog box for status change */}

            <Dialog
              open={openStatusDilogue}
              onClose={handleCloseStatusDilogue}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Status Seller ?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to change status of Seller ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseStatusDilogue}>Cancle</Button>
                <Button onClick={updateStatusSeller} autoFocus>
                  Update
                </Button>
              </DialogActions>
            </Dialog>

            {/* dialog box for auction rights change */}

            <Dialog
              open={openRightsDilogue}
              onClose={handleCloseRightsDilogue}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Status Seller ?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to change user rights of Seller ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={updateRightsSeller}>Yes</Button>
                <Button onClick={handleCloseRightsDilogue} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>

            <Modal
              open={modalOpen.visible}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="modalMain"
            >
              <Box sx={style} className="modalMain">
                <Typography className={classes.headText}>
                  Company Information
                </Typography>
                <form onSubmit={handleSubmitSeller}>
                  <div className={classes.forms}>
                    <div className="row">
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mb-3">
                            <Form.Label className={classes.label}>
                              Company Name
                            </Form.Label>
                            <Form.Control
                              className={classes.input}
                              type="text"
                              name="companyName"
                              placeholder="Company Name"
                              required
                              disabled={!editProfile}
                              value={
                                userInfoSeller.companyName
                                  ? userInfoSeller.companyName
                                  : profileDetail.list.companyName
                              }
                              onChange={handleChangeSeller}
                            />
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mb-3">
                            <Form.Label className={classes.label}>
                              Bussiness Type
                            </Form.Label>
                            <Form.Control
                              className={classes.input}
                              type="text"
                              name="businessType"
                              placeholder="Business Type"
                              required
                              disabled={!editProfile}
                              value={
                                userInfoSeller.businessType
                                  ? userInfoSeller.businessType
                                  : profileDetail.list.businessType
                              }
                              onChange={handleChangeSeller}
                            />
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6">
                        <Box className={classes.forms}>
                          <Form.Group className="mb-3">
                            <Form.Label className={classes.label}>
                              Enter your Business Identification Number(BI, TIN,
                              EIN) ?
                            </Form.Label>
                            <Form.Control
                              className={classes.input}
                              type="text"
                              name="bi_tin_ein"
                              required
                              disabled={!editProfile}
                              value={
                                userInfoSeller.bi_tin_ein
                                  ? userInfoSeller.bi_tin_ein
                                  : profileDetail.list.bi_tin_ein
                              }
                              onChange={handleChangeSeller}
                            />
                          </Form.Group>
                        </Box>
                      </div>
                      <div className="col-md-6"></div>

                      <div className="col-md-12">
                        <div className="row">
                          <Form.Label className={classes.label}>
                            Address
                          </Form.Label>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  required
                                  name="streetOne"
                                  placeholder="Street Address"
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.streetOne
                                      ? userInfoSeller.streetOne
                                      : profileDetail.list.streetOne
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  required
                                  name="streetTwo"
                                  placeholder="Street Address Line 2"
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.streetTwo
                                      ? userInfoSeller.streetTwo
                                      : profileDetail.list.streetTwo
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  required
                                  placeholder="City"
                                  disabled={!editProfile}
                                  name="city"
                                  value={
                                    userInfoSeller.city
                                      ? userInfoSeller.city
                                      : profileDetail.list.city
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  required
                                  placeholder="Postal/Zip Code"
                                  disabled={!editProfile}
                                  name="postalCode"
                                  value={
                                    userInfoSeller.postalCode
                                      ? userInfoSeller.postalCode
                                      : profileDetail.list.postalCode
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Select
                                  aria-label="Default select example"
                                  className={classes.input}
                                  name="country"
                                  onChange={handleChangeSeller}
                                  required={true}
                                  value={
                                    userInfoSeller.country
                                      ? userInfoSeller.country
                                      : profileDetail.list.country
                                  }
                                >
                                  <option>Country</option>
                                  {countriesList.list.map((country, index) => {
                                    return (
                                      <option value={country.id}>
                                        {country.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Select
                                  aria-label="Default select example"
                                  className={classes.input}
                                  name="state"
                                  onChange={handleChangeSeller}
                                  required={true}
                                  value={
                                    userInfoSeller.state
                                      ? userInfoSeller.state
                                      : profileDetail.list.state
                                  }
                                >
                                  <option>States</option>
                                  {stateList.list.map((state, index) => {
                                    return (
                                      <option value={state.id}>
                                        {state.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Box>
                          </div>
                        </div>
                      </div>
                      <Typography className={classes.headText}>
                        Company Representative Information:
                      </Typography>
                      <div className="comapnyRe">
                        <div className="row">
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Label className={classes.label}>
                                  Name
                                </Form.Label>
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  name="firstName"
                                  placeholder="First Name"
                                  required
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.firstName
                                      ? userInfoSeller.firstName
                                      : profileDetail.list.firstName
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Label className={classes.label}>
                                  Last Name
                                </Form.Label>
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  name="lastName"
                                  placeholder="Last Name"
                                  required
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.lastName
                                      ? userInfoSeller.lastName
                                      : profileDetail.list.lastName
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="mb-3">
                                <Form.Label className={classes.label}>
                                  Title
                                </Form.Label>
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  name="title"
                                  placeholder="title"
                                  required
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.title
                                      ? userInfoSeller.title
                                      : profileDetail.list.title
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className={classes.label}>
                              Contact Number
                            </Form.Label>
                            <Box sx={{ display: "flex" }}>
                              <Form.Control
                                style={{ width: "20%" }}
                                className={classes.input}
                                type="number"
                                placeholder="+91"
                                required
                                disabled={true}
                              />
                              <Form.Control
                                className={classes.input}
                                type="number"
                                name="contactNumber"
                                placeholder="Contact Number"
                                required
                                disabled={!editProfile}
                                value={
                                  userInfoSeller.contactNumber
                                    ? userInfoSeller.contactNumber
                                    : profileDetail.list.contactNumber
                                }
                                onChange={handleChangeSeller}
                              />
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className={classes.label}>
                              Alternate Contact Number
                            </Form.Label>
                            <Box sx={{ display: "flex" }}>
                              <Form.Control
                                style={{ width: "20%" }}
                                className={classes.input}
                                type="number"
                                placeholder="+91"
                                required
                                disabled={true}
                              />
                              <Form.Control
                                className={classes.input}
                                type="number"
                                name="alternateContactNumber"
                                placeholder="Alternate Contact Number"
                                required
                                disabled={!editProfile}
                                value={
                                  userInfoSeller.alternateContactNumber
                                    ? userInfoSeller.alternateContactNumber
                                    : profileDetail.list.alternateContactNumber
                                }
                                onChange={handleChangeSeller}
                              />
                            </Box>
                          </div>
                          <div className="col-md-6">
                            <Box className={classes.forms}>
                              <Form.Group className="">
                                <Form.Label className={classes.label}>
                                  Email
                                </Form.Label>
                                <Form.Control
                                  className={classes.input}
                                  type="text"
                                  name="email"
                                  placeholder="email"
                                  required
                                  disabled={!editProfile}
                                  value={
                                    userInfoSeller.email
                                      ? userInfoSeller.email
                                      : profileDetail.list.email
                                  }
                                  onChange={handleChangeSeller}
                                />
                              </Form.Group>
                            </Box>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {editProfile ? (
                          <Box className={classes.saveNextBtns}>
                            <Button
                              onClick={() => SeteditProfile(false)}
                              className={classes.pre}
                            >
                              Cancel
                            </Button>
                            <Button
                              className={classes.next}
                              sx={{ marginLeft: "1em" }}
                              type="submit"
                            >
                              Save
                            </Button>
                          </Box>
                        ) : (
                          <div className={classes.saveBtn}>
                            <Button
                              onClick={() => SeteditProfile(true)}
                              className={classes.next}
                              type="submit"
                            >
                              Edit Profile
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </Box>
            </Modal>
          </div>
        );
    }
  };
  return (
    <div className={classes.section}>
      <div className="container">
        <div className={classes.Dashboard}>
          <div className={classes.dashboardCard}>
            <DashboardMenu />
            <div className={classes.dashboardCards}>{renderComponent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashSeller;
