import React from "react";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";

const BulkUploadModal = ({
  show,
  handleCloseModal,
  selectedOption,
  handleDropdownChange,
  uploadRules,
  handleSubmit,
  handleChange,
  handleTimeChange,
  dateHandler,
  handleDownload,
  eventFormat,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      className="editListModal"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Bulk Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ps-4 pe-4">
          {/* className='editModalBox' */}
          <div className="row">
            <div className="col-md-3 bg-x">
              <Form.Label>Select Time Type</Form.Label>
              <Form.Select
                value={selectedOption}
                onChange={handleDropdownChange}
                className="editDropdown"
              >
                <option value="EXCEL">Excel</option>
                <option value="MANUAL">Manual</option>
              </Form.Select>
            </div>
            <div className="col-md-9">
              {selectedOption === "MANUAL" && (
                // <form onSubmit={handleSubmit} className="modalInsideForm">
                <div className="row">
                  <div className="col-md-4 dateSelector">
                    <Form.Label>Select Start Date and Time</Form.Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className="datePicker newDatePicker"
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            className="form-control form-control-sm p-0 m-0"
                          />
                        )}
                        value={uploadRules.manualStartDate}
                        onChange={(date) => {
                          dateHandler("manualStartDate", date);
                        }}
                        minDate={moment().toDate()}
                        style={{ padding: "7px 14px !important" }}
                      />
                    </LocalizationProvider>
                  </div>
                  {eventFormat === "ENGLISH_AUCTION" && (
                    <>
                      <div className="col-md-4 dateSelector">
                        <Form.Label>Select End Date and Time</Form.Label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            className="datePicker newDatePicker"
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                className="form-control form-control-sm p-0 m-0"
                              />
                            )}
                            value={uploadRules.manualEndDate}
                            onChange={(date) => {
                              dateHandler("manualEndDate", date);
                            }}
                            minDate={moment().toDate()}
                            style={{ padding: "7px 14px !important" }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="col-md-4">
                        <Form.Label>Select Interval</Form.Label>
                        <Form.Select
                          onChange={handleTimeChange}
                          className="editDropdown"
                        >
                          <option value="">Select</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="60">60</option>
                        </Form.Select>
                      </div>
                    </>
                  )}
                </div>
                // </form>
              )}
            </div>
          </div>

          <form onSubmit={handleSubmit} className="modalInsideForm mt-4">
            <div className="bg-secondary new-background">
              <input
                type="file"
                name="file"
                onChange={handleChange}
                accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
            <div className="modalUpload mt-3">
              <button
                type="button"
                value="Download"
                className="editUploadButton mx-3"
                onClick={handleDownload}
              >
                Download
              </button>
              <button type="submit" className="editUploadButton">
                Upload
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BulkUploadModal;
