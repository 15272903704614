import React, { useEffect } from "react";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import Countdown from "react-countdown";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { MdSignalWifiStatusbarConnectedNoInternet as WifiSVG } from "react-icons/md";

import { getUserData } from "../../../common";
import useStyles from "./styles";
import MyBidRankComponent from "../Auction/AuctionDetail/BidRankComponent";
import CountdownRenderer from "../Auction/AuctionDetail/CountdownRenderer";

const capitalize = (str) => {
  const capitalizedWords = str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  return capitalizedWords.join(" ");
};

const getLocationString = (event, location) => {
  if (location) return location;
  if (event?.city && event?.country) return `${event.city}, ${event.country}`;
  if (event?.city) return `${event?.city}`;
  if (event?.country) return `${event?.country}`;
  return "";
};

const createBidListLots = (
  lots,
  userAmount,
  eventDetails,
  handleUserAmountChange,
  storeHandler,
  isOnline,
  lotBidEnded,
  styles
) => {
  const timeZone = momentTimeZone.tz.guess();
  const userData = getUserData();
  let currentTime = moment().tz(timeZone).format();
  const isForwardAuction = eventDetails?.eventType === "FORWARD_AUCTION";

  const columns = [
    {
      name: "Lot No.",
      width: "90px",
      selector: (data) => String(data.lotNumber).toUpperCase(),
      cell: (data) => capitalize(String(data.lotNumber)),
      sortable: true,
    },
    {
      name: "Short Description",
      width: "180px",
      selector: (data) => String(data.shortDescription),
      cell: (data) => (
        <Tooltip title={String(data.shortDescription)}>
          {String(data.shortDescription)}
        </Tooltip>
      ),
      sortable: false, // Cannot sort JSX directly
    },
    {
      name: "Location",
      width: "180px",
      selector: (data) => String(data.location),
      sortable: true,
    },
    {
      name: "Qty & Unit",
      width: "110px",
      selector: (data) => Number(data.quantity), // Sorting by quantity
      cell: (data) => `${Number(data.quantity)} ${data.unit.toUpperCase()}`,
      sortable: true,
    },
    {
      name: "Start Price",
      width: "120px",
      selector: (data) => Number(data.startPrice),
      sortable: true,
    },
    {
      name: `${isForwardAuction ? "INCR" : "DECR"} (${
        eventDetails?.currency || "-"
      })`,
      width: "120px",
      selector: (data) => Number(data.increment),
      sortable: true,
    },
    {
      name: "Remaining Time",
      width: "190px",
      selector: (data) => moment(data.remainingTime).valueOf(), // Sort by timestamp
      cell: (data, index) => (
        <Typography className={styles.amountSecondValueLive}>
          <Countdown
            key={data.lotNumber} // Avoid unnecessary re-renders
            className={styles.bidLive}
            date={moment(data.remainingTime).tz(timeZone).format()}
            onComplete={() => !data.finalizing && lotBidEnded(data, index)}
            renderer={(...args) => CountdownRenderer(data.finalizing, ...args)}
          />
        </Typography>
      ),
      sortable: true,
    },
    {
      name: `Lead Bid (${eventDetails?.currency || ""})`,
      width: "140px",
      selector: (data) => Number(data.highestBid) || 0,
      cell: (data) => data.highestBid || "-",
      sortable: true,
    },
    {
      name: "Bid Rate",
      width: "150px",
      selector: (data) => Number(userAmount[`${data.lotNumber}`]) || 0,
      cell: (data) => (
        <TextField
          size="small"
          type="number"
          variant="outlined"
          key={data.lotNumber}
          disabled={data.finalizing}
          InputProps={{
            inputProps: {
              min: 0,
              value: userAmount[`${data.lotNumber}`] || "",
              className: styles.auctionAmountField,
              onChange: ({ target }) => {
                handleUserAmountChange(
                  data.lotNumber,
                  target.value,
                  userAmount
                );
              },
            },
          }}
        />
      ),
      sortable: true,
    },
    {
      name: "Bid Now",
      selector: () => null, // Sorting disabled
      cell: (data) =>
        isOnline ? (
          <Button
            disabled={userAmount[`${data.lotNumber}`] <= 0}
            onClick={() => storeHandler(data, userAmount[`${data.lotNumber}`])}
            className={
              userAmount[`${data.lotNumber}`] <= 0
                ? styles.auctionBidErrorBox
                : styles.auctionBidButton
            }
          >
            Bid Now
          </Button>
        ) : (
          <Box className={styles.auctionBidErrorBox}>
            Network Lost{" "}
            <WifiSVG style={{ marginLeft: "5px", fontSize: "18px" }} />
          </Box>
        ),
      sortable: false,
    },
  ];

  const createdLots =
    lots?.map((lot, index) => ({
      id: index + 1,
      location: getLocationString(lot.event, lot.location),
      ...lot,
    })) || [];

  return { columns, data: createdLots };
};

const PostBidLotListTable = (props) => {
  const {
    lots,
    userAmount,
    eventDetails,
    handleUserAmountChange,
    storeHandler,
    isOnline,
    lotBidEnded,
  } = props;
  const styles = useStyles();

  const { columns, data } = createBidListLots(
    lots,
    userAmount,
    eventDetails,
    handleUserAmountChange,
    storeHandler,
    isOnline,
    lotBidEnded,
    styles
  );

  return (
    <div
      style={{
        width: "90%",
        margin: "auto",
        marginBottom: "2rem",
      }}
    >
      <DataTableExtensions
        print={false}
        columns={columns}
        export={false}
        exportHeaders={false}
        data={data}
      >
        <DataTable
          pagination
          noHeader
          highlightOnHover
          data={data}
          className="DataTable"
          defaultSortField="id"
          defaultSortAsc={false}
        />
      </DataTableExtensions>
    </div>
  );
};

export default PostBidLotListTable;
