import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { AiFillEye } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { Button, Modal, Typography } from "@mui/material";
import useStyles from "./viewBuyer.style";
import { AiOutlineEdit } from "react-icons/ai";
import { RiInboxArchiveLine } from "react-icons/ri";
import Form from "react-bootstrap/Form";
import BuyerTable from "../../../../../commonComponents/BuyerTable";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import getViewByBuyerList from "../../../../../../api/getViewByBuyerList";
import CloseIcon from "@mui/icons-material/Close";

export default function ViewBuyer() {
  const classes = useStyles();
  const location = useLocation();
  const eventId = location.state.data.eventId;
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, updateClearSelectedRows] = useState(false);
  const [buyerLotsModal, toggleBuyerLotsModal] = useState(false);
  const [lots, setLots] = useState([]);

  const [tableEventsData, setTableEventsData] = useState([]);
  console.clear();
  console.log(clearSelectedRows);
  const [columnsEventBuyers, setColumnsEventBuyers] = useState([
    {
      name: "Buyer name",
      selector: (data) => String(data.name || ""),
      cell: (data) => String(data.name),
      sortable: true,
    },
    {
      name: "Buyer email",
      selector: (data) => String(data.email || ""),
      cell: (data) => String(data.email),
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: (data) => String(data.contactNumber || ""),
      cell: (data) => String(data.contactNumber),
      sortable: false,
    },
    {
      name: "Alt. Contact No.",
      selector: (data) => String(data.alternateContactNumber || ""),
      cell: (data) => String(data.alternateContactNumber),
      sortable: false,
    },
    {
      name: "Lots",
      selector: (data) => Number(data.totalLots) || 0,
      cell: (data) => (
        <div>
          <RiInboxArchiveLine className={classes.lots} />{" "}
          {String(data.totalLots)}
        </div>
      ),
      sortable: true,
      export: true,
    },
  ]);

  const eventBuyersList = async () => {
    console.log("eventBuyersList called");
    try {
      const payload = { auctionId: eventId };
      const dataEventBuyers = await getViewByBuyerList(payload);

      if (dataEventBuyers) {
        const eventsBuyerList = dataEventBuyers.data.map((data) => {
          return {
            name: `${data.user.firstName} ${data.user.lastName}`,
            email: data.user.email,
            contactNumber: data.user.contactNumber,
            alternateContactNumber: data.user.alternateContactNumber,
            eventBuyerId: data.eventBuyerId,
            totalLots: data.totalLots,
          };
        });

        setTableEventsData(eventsBuyerList);
        // selectedRows.length = 0;
        // console.log("setSelectedRowszzz", selectedRows)
      } else throw new Error(dataEventBuyers);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    console.log("useEffect called");
    setTimeout(() => {
      eventBuyersList();
    }, 1000);
    console.log(("Lots", lots));
    return () => {
      return false;
    };
  }, [eventId]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    //   border: "1px solid #989898",
    boxShadow: "none",
    p: 4,
  };

  const [modalOpen, setModalOpen] = useState({});

  const handleOpen = (text) =>
    setModalOpen({
      visible: true,
      type: text,
    });
  const handleClose = (text) =>
    setModalOpen({
      visible: false,
      type: text,
    });

  return (
    <Box sx={{ width: "100%", padding: "0px !important" }}>
      {selectedRows.length > 0 && (
        //
        <div
          className="email-container"
          style={{ background: "#2D55EB", paddingTop: "10px", height: "55px" }}
        >
          <Button
            type="primary"
            className="addlots"
            onClick={() => {
              handleOpen("Add");
            }}
          >
            Add Lots
          </Button>
          <Button
            type="primary"
            className="addlots"
            sx={{ color: "red !important" }}
            onClick={() => {
              handleOpen("Remove");
            }}
          >
            Remove Lots
          </Button>
        </div>
      )}
      <DataTableExtensions
        print={false}
        export={false}
        exportHeaders={false}
        columns={columnsEventBuyers}
        data={tableEventsData}
      >
        <DataTable
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          export={true}
          exportHeaders={false}
          selectableRows={selectedRows}
          onSelectedRowsChange={(data) => {
            setSelectedRows(data.selectedRows);
          }}
          //clearSelectedRows={false}
          className="DataTable"
        />
      </DataTableExtensions>
      <Modal
        open={modalOpen.visible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            className="hoverClose"
            onClick={handleClose}
            sx={{
              backgroundColor: "rgb(45, 85, 235)",
              color: "#fff",
              marginBottom: "1em",
            }}
          >
            <CloseIcon />
          </Button>
          <BuyerTable
            type={modalOpen.type}
            modalHandleClose={handleClose}
            eventBuyersList={eventBuyersList}
            data={[...selectedRows]}
            updateClearSelectedRows={updateClearSelectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Box>
      </Modal>
    </Box>
  );
}
// 483087c8-78ce-4874-9cd8-f95f4e33b1e3
// 65c9d51c-f859-451f-8b29-6d91dbc9f3c0
// 31f06828-0aa7-48f2-9222-dfc1ee4bb5c5
