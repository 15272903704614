import React, { useState, useEffect } from "react";
import lodash from "lodash";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Pagination } from "@mui/material";
import { Box } from "@mui/system";
import Form from "react-bootstrap/Form";
import useStyles from "./auctionCard.style";
import getEventsListAPI from "../../../../api/getEventsListAPI";
import AuctionCard from "../../AuctionCard/index";

function AuctionCards(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [events, updateEvents] = useState({ apiState: "loading", list: [] });

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState();
  const [pageCount, setPageCount] = React.useState();
  const [TotalEvents, setTotalEvents] = React.useState();
  const [lotStatus, setLotStatus] = React.useState("live");

  useEffect(() => {
    getEventsList(page, lotStatus);
    window.scrollTo(0, 0);
    return () => null;
  }, [props]);

  const handleChange = (event, value) => {
    getEventsList(value, lotStatus);
    setPage(value);
  };

  const lotStatusChange = (event) => {
    setPage(1);
    setLotStatus(event.target.value);
    getEventsList(1, event.target.value);
  };

  const getEventsList = async (value, lotStatus) => {
    try {
      const payload = {
        pageSize: pageSize,
        page: value,
        lotStatus: lotStatus,
        sellerId: props.auctionIds,
      };

      if (!lodash.isEmpty(props.countryId)) {
        payload.countryId = props.countryId;
      }

      if (!lodash.isEmpty(props.stateId)) {
        payload.stateId = props.stateId;
      }

      const { data } = await getEventsListAPI(payload);

      if (data) {
        updateEvents({ apiState: "success", list: data.data, meta: data.meta });
        setPageCount(data.meta.totalPages);
        setTotalEvents(data.meta.totalEvents);
        setPageSize(data.meta.pageSize);
      } else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
    return true;
  };

  return (
    <div className={classes.auctioncard}>
      <div className="container">
        <div className={classes.auctionHead}>
          <Box>
            <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
              Auction
            </Typography>
            <Typography>
              We found {TotalEvents} item{TotalEvents > 1 ? "s" : ""}
            </Typography>
          </Box>
          <Box>
            <Form.Select
              aria-label="Default select example"
              className={classes.input}
              required
              onChange={lotStatusChange}
              value={lotStatus}
              placeholder="Sort by"
            >
              <option className={classes.liveD} value="live">
                Live
              </option>
              <option className={classes.UpcomingD} value="upcoming">
                Upcoming
              </option>
              <option className={classes.endedD} value="ended">
                Ended
              </option>
            </Form.Select>
          </Box>
        </div>
        <div className={classes.cardAuction}>
          <div className="row">
            {events.list.map((event, index) => (
              <AuctionCard key={index} {...event} />
            ))}
          </div>
        </div>
        <Pagination
          count={pageCount}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
          page={page}
          className="pagination"
          sx={{ justifyContent: "center" }}
        />
      </div>
    </div>
  );
}

export default React.memo(AuctionCards);
