import "react-data-table-component-extensions/dist/index.css";

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

import useStyles from "../dashAuction.style";
import { getTimeZone, localTimeZone } from "../../../../common";
import getEventDetail from "../../../../api/getEventDetail";
import { CircularProgress, Tooltip, Zoom } from "@mui/material";
import getLogs from "../../../../api/getLogs";
import getAuctionPostBidsAPI from "../../../../api/adminAPI/getAuctionPostBidsAPI";

function AuctionDetailLogs({ AuctionView, eventId }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [logTableData, setLogTableData] = useState([]);
  const [auctionDetails, setAuctionDetails] = useState(null);
  const [postBidData, setPostBidData] = useState([]);

  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const fetchEventDetails = async () => {
    setLoading(true);
    const { data } = await getEventDetail({ auctionId: eventId });
    const { data: logData } = await getLogs({ eventId, tableName: "bids" });

    setLogTableData(
      logData?.logs?.map((log, index) => ({
        id: index + 1,
        lotId: log.lotNumber,
        bidder: `${log.firstName || ""} ${log.lastName || ""}`,
        status: log.data.status,
        amount: log.data.amount,
        reason: log.data.reason,
        isAutobid: log.data.isAutobid,
        biddingTime: moment
          .utc(log.data.biddingTime)
          .local()
          .format("DD/MM/YY HH:mm:ss"),
      })) || []
    );

    setAuctionDetails(data);

    try {
      const postBidsResponse = await getAuctionPostBidsAPI(eventId);
      console.log("->> ", postBidsResponse);
      if (postBidsResponse) {
        const postBidDataDetails = postBidsResponse.data?.map(
          (details, index) => {
            return {
              id: index + 1,
              lotNumber: details.eventItem.lotNumber,
              biddingTime: moment(details.biddingTime)
                .tz(getZone)
                .format("DD/MM/YYYY hh:mm:ss"),
              status: details.status,
              reason: details.reason,
              amount: details.amount,
              bidder: `${details.users.firstName} ${details.users.lastName}`,
            };
          }
        );

        setPostBidData(postBidDataDetails);
      } else throw new Error(postBidsResponse.data);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    // 1. fetch auction details
    // 2. fetch bid logs of that auction
    fetchEventDetails();

    return () => {
      return false;
    };
  }, []);

  const nextColumns = [
    {
      name: "Sr.",
      cell: ({ id }) => String(id),
      selector: ({ id }) => Number(id) || 0, // Ensures numeric sorting
      sortable: true,
    },
    {
      name: "Lot No.",
      cell: ({ lotId }) => String(lotId),
      selector: ({ lotId }) => Number(lotId) || 0, // Ensures numeric sorting
      sortable: true,
    },
    {
      name: "Bidder",
      cell: ({ bidder = "N/A" }) => bidder,
      selector: ({ bidder = "N/A" }) => bidder.toLowerCase(), // Ensures string sorting
      sortable: true,
    },
    {
      name: "Bid Type",
      cell: ({ isAutobid }) => (isAutobid ? "Autobid" : "Manual"),
      sortable: false,
    },
    {
      name: "Bid Status",
      cell: ({ status = "-" }) => (
        <span
          style={{
            color: status === "SUCCESS" ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {status}
        </span>
      ),
      selector: ({ status }) => status || "-", // Ensures string sorting
      sortable: false,
    },
    {
      name: "Bid Failure Reason",
      cell: ({ reason }) => (
        <Tooltip title={reason}>
          <span
            style={{
              color: "red",
            }}
          >
            {reason?.length ? reason : "-"}
          </span>
        </Tooltip>
      ),
      width: "170px",
      sortable: false,
    },
    {
      name: "Bid Amount",
      cell: ({ amount }) => Number(amount),
      selector: ({ amount }) => Number(amount) || 0, // Ensures numeric sorting
      sortable: true,
    },
    {
      name: "Bid Date & Time",
      cell: ({ biddingTime }) => biddingTime,
      selector: ({ biddingTime }) => new Date(biddingTime), // Ensures proper date sorting
      sortable: true,
    },
  ];

  const postBidsColumns = [
    {
      name: "Sr.",
      cell: ({ id }) => String(id),
      selector: ({ id }) => Number(id) || 0,
      width: "80px",
      sortable: true,
    },
    {
      name: "Lot No",
      cell: ({ lotNumber }) => String(lotNumber),
      selector: ({ lotNumber }) => Number(lotNumber) || 0,
      width: "140px",
      sortable: true,
    },
    {
      name: "Bidder",
      cell: ({ bidder }) => (
        <Tooltip
          title={bidder}
          placement="top-start"
          TransitionComponent={Zoom}
        >
          <span className={classes.ellipsisText}>{bidder}</span>
        </Tooltip>
      ),
      selector: ({ bidder = "N/A" }) => bidder.toLowerCase(),
      width: "200px",
      sortable: true,
    },
    {
      name: "Bid Type",
      cell: () => "Post Bid",
      sortable: false,
      width: "180px",
    },
    {
      name: "Bid Status",
      cell: ({ status = "-" }) => (
        <span
          style={{
            color: status === "SUCCESS" ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {status}
        </span>
      ),
      selector: ({ status }) => status || "-",
      sortable: false,
    },
    {
      name: "Bid Failure Reason",
      cell: ({ reason }) => (
        <Tooltip title={reason}>
          <span
            style={{
              color: "red",
            }}
          >
            {reason?.length ? reason : "-"}
          </span>
        </Tooltip>
      ),
      width: "170px",
      sortable: false,
    },
    {
      name: "Bid Price",
      cell: ({ amount }) => Number(amount)?.toLocaleString(),
      selector: ({ amount }) => Number(amount) || 0,
      width: "120px",
      sortable: true,
    },
    {
      name: "Bid Date & Time",
      cell: ({ biddingTime }) => String(biddingTime),
      selector: ({ biddingTime }) => new Date(biddingTime),
      sortable: true,
      width: "180px",
    },
  ];

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.lotId,
      columns.bidder,
      columns.status,
      columns.amount,
      columns.biddingTime,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "bid-log.xlsx");
  };

  const exportToXLSXPostBid = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.lotNumber,
      columns.bidder,
      "Post bid",
      columns.status,
      columns.reason || "-",
      columns.amount,
      columns.biddingTime,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "bid-logs.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(logTableData, nextColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  const PostBidExportButton = () => (
    <Button onClick={() => exportToXLSXPostBid(postBidData, postBidsColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  if (loading) {
    return (
      <div className="topDetail">
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "4rem",
          }}
        >
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="topDetail">
        <div className="row">
          <div className="col-md-12 m-auto">
            <div className={classes.BuyEventHeading}>
              <h3>
                <Button sx={{ color: "#000" }} onClick={AuctionView}>
                  <ArrowBackIcon />
                </Button>
                Go back to auction list
              </h3>
              <h3 className="mob">Event Details</h3>
            </div>
          </div>
          <div className="col-md-12" style={{ marginTop: "1rem" }}>
            <div className={classes.detailCard}>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className={classes.Detail}>
                        <p className={classes.SideLine}>
                          Name &nbsp; : &nbsp;{" "}
                          <span>{auctionDetails.auctionTitle}</span>
                        </p>
                        <p className={`${classes.SideLine}`}>
                          Auctioneer &nbsp; : &nbsp;{" "}
                          <span>{auctionDetails?.auctioneerName}</span>
                        </p>
                        <p
                          className={`${classes.SideLine} ${classes.marginremove}`}
                        >
                          Bid Start Date & Time &nbsp; : &nbsp;{" "}
                          <span>
                            {moment
                              .utc(auctionDetails?.bidStartDateTime)
                              .local()
                              .format("DD/MM/YY HH:mm:ss")}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={classes.Detail}>
                        <p className={`${classes.SideLine}`}>
                          Currency&nbsp;: &nbsp;
                          <span>{auctionDetails?.currency}</span>
                        </p>
                        <p className={`${classes.SideLine}`}>
                          Status&nbsp;:&nbsp;{" "}
                          <span>{auctionDetails.auctionStatus}</span>
                        </p>
                        <p
                          className={`${classes.SideLine} ${classes.marginremove}`}
                        >
                          Bid End Date & Time &nbsp; : &nbsp;{" "}
                          <span>
                            {moment
                              .utc(auctionDetails?.bidEndDateTime)
                              .local()
                              .format("DD/MM/YY HH:mm:ss")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className={classes.Detail}>
                        <p className={classes.SideLine}>
                          Auction Type &nbsp; : &nbsp;{" "}
                          <span>{auctionDetails?.eventType}</span>
                        </p>
                        <p className={`${classes.SideLine}`}>
                          Auction Id &nbsp; : &nbsp;{" "}
                          <span>{auctionDetails.eventId}</span>
                        </p>
                        <p
                          className={`${classes.SideLine} ${classes.marginremove}`}
                        >
                          Publish End Date & Time &nbsp; : &nbsp;{" "}
                          <span>
                            {moment
                              .utc(auctionDetails?.publishDateTime)
                              .local()
                              .format("DD/MM/YY HH:mm:ss")}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={classes.Detail}>
                        <p>
                          Owner Of lot&nbsp;: &nbsp;
                          <span>{auctionDetails?.ownerOfLots}</span>
                        </p>
                        <p>
                          Location&nbsp;:&nbsp;{" "}
                          <span>
                            {auctionDetails?.country},{" "}
                            {auctionDetails?.province}
                          </span>
                        </p>
                        <p className={`${classes.marginremove}`}>
                          Description &nbsp; : &nbsp;{" "}
                          <span>{auctionDetails?.description}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="AuctionTable" style={{ marginTop: "2rem" }}>
        <h3>Bid Log</h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={logTableData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<ExportButton />}
          />
        </DataTableExtensions>
      </div>
      <div className="AuctionTable" style={{ marginTop: "2rem" }}>
        <h3>Post Bid Logs</h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={postBidsColumns}
          data={postBidData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<PostBidExportButton />}
          />
        </DataTableExtensions>
      </div>
    </>
  );
}

export default AuctionDetailLogs;
