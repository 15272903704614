import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import useStyles from "../../../../Create/Event/Rules/rules.style";
import Form from "react-bootstrap/Form";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateEventRulesAPI } from "../../../../../../api/adminAPI/updateEventRulesAPI";
import currency from "../../../../../components/Create/Event/Rules/currency.json";
import getEventDetails from "../../../../../../api/getEventDetail";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../../../constants/routes";
import lodash, { isEmpty, omit } from "lodash";
import SelectOrAddCurrency from "../../../../SelectOrAddCurrency";
import currencies from "../../../../../../constants/currencies.json";

function EditRules({ onBack, method, eventEditId }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [eventRules, setEventRules] = useState({
    previewPeriod: "NO",
    overTimePeriodInMinutes: "",
    publishDateTime: moment().utc().toISOString(),
    bidStartDateTime: moment().utc().toISOString(),
    bidEndDateTime: moment().utc().toISOString(),
    overTimePeriodInMinutes: 0,
    extendWithinBidEndMinutes: 0,
    currency: "",
    showH1L1Bid: "YES",
    eventMessage: "",
    incrementBidAmountBy: "VALUE",
    auctionStatus: "ACTIVE",
    eventFormat: "ENGLISH_AUCTION",
    variableIncrementDecrement: "YES",
  });

  const [options, setOptions] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  useEffect(() => {
    fetchEventDetails();
    return () => null;
  }, [eventEditId]);

  useEffect(() => {
    if (!options.length) {
      const currencyArray = Object.entries(currencies[0]).map(
        ([key, value]) => ({
          value: key,
          label: value.code,
        })
      );
      setOptions(currencyArray);
    }
  }, []);

  useEffect(() => {
    setEventRules((prevEventItemRules) => ({
      ...prevEventItemRules,
      currency: selectedCurrency,
    }));
  }, [selectedCurrency]);

  useEffect(() => {
    const currencyArray = Object.entries(currency[0]).map(([key, value]) => ({
      value: key,
      label: value.code,
    }));
    setCurrencyData(currencyArray);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const validate = (name, value) => {
    //A function to validate each input values
    switch (name) {
      case "publishDateTime":
        if (value === null) {
          // we will set the error publishDateTime

          setErrors({
            ...errors,
            publishDateTime: "Publish Date Time Can Not Be Empty.",
          });
        } else {
          // set the error publishDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "publishDateTime");
          setErrors(newObj);
        }
        break;

      case "bidStartDateTime":
        if (value === null) {
          // we will set the error bidStartDateTime

          setErrors({
            ...errors,
            bidStartDateTime: "Bid Start Date Time Can Not Be Empty.",
          });
        } else {
          // set the error bidStartDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "bidStartDateTime");
          setErrors(newObj);
        }
        break;

      case "bidEndDateTime":
        if (value === null) {
          // we will set the error bidEndDateTime

          setErrors({
            ...errors,
            bidEndDateTime: "Bid End Date Time Can Not Be Empty.",
          });
        } else {
          // set the error bidEndDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "bidEndDateTime");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const handleChange = (event) => {
    setEventRules({
      ...eventRules,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const currencyToSubmit = selectedCurrency || eventRules.currency;
    if (!isEmpty(errors)) {
      toast("Please Provide Correct Values!");
    } else {
      try {
        const payload = {
          ...eventRules,
          currency: currencyToSubmit,
        };

        if (payload.eventFormat === "ENGLISH_AUCTION") {
          payload.showH1L1Bid = eventRules.showH1L1Bid;
          payload.showRankTill =
            eventRules.showH1L1Bid === "YES" ? eventRules.showRankTill : null;
        } else {
          delete payload.showH1L1Bid;
          delete payload.showRankTill;
        }

        delete payload.eventFormat;
        const updateResponse = await updateEventRulesAPI(payload, eventEditId);

        if (updateResponse.status === 200 || updateResponse.status === 201) {
          toast("Event Rules Create Successfully!");
          setTimeout(() => {
            method(event, 1);
          }, 1500);
        } else {
          throw new Error("Network Error!");
        }
      } catch (error) {
        if (error.isAxiosError) {
          console.error("Axios error:", error.message);
          console.error("Status:", error.response.status);
          console.log("error res", error.response.data?.error);
          toast(error.response.data?.error);
          setIsLoading(false);
        } else if (error.response.status === 500) {
          toast("Network Error!");
          setIsLoading(false);
        } else if (error.request) {
          toast("Something went wrong!");
          setIsLoading(false);
        } else {
          console.log("Error", error.message.code);
          setIsLoading(false);
        }
      }
    }
  };

  const fetchEventDetails = async () => {
    try {
      const eventDetails = await getEventDetails({ auctionId: eventEditId });

      if (eventDetails.status === 200) {
        setEventRules({
          previewPeriod: eventDetails.data.previewPeriod,
          overTimePeriodInMinutes: eventDetails.data.overTimePeriodInMinutes,
          publishDateTime: moment(eventDetails.data.publishDateTime),
          bidStartDateTime: moment(eventDetails.data.bidStartDateTime),
          bidEndDateTime: moment(eventDetails.data.bidEndDateTime),
          overTimePeriodInMinutes: eventDetails.data.overTimePeriodInMinutes,
          eventFormat: eventDetails.data.eventFormat,
          extendWithinBidEndMinutes:
            eventDetails.data.extendWithinBidEndMinutes,
          currency: eventDetails.data.currency,
          showH1L1Bid: String(eventDetails.data.showH1L1Bid),
          eventMessage: eventDetails.data.eventMessage,
          incrementBidAmountBy: eventDetails.data.incrementBidAmountBy,
          auctionStatus: eventDetails.data.auctionStatus,
          showRankTill: eventDetails.data.showRankTill,
          variableIncrementDecrement:
            eventDetails.data.variableIncrementDecrement,
        });

        const value = eventDetails.data.currency;
        setSelectedCurrency(value);
        if (value && !options.find((currency) => currency.value === value)) {
          const currencyArray = Object.entries(currencies[0]).map(
            ([key, value]) => ({
              value: key,
              label: value.code,
            })
          );

          const updatedOptions = [...currencyArray, { value, label: value }];
          setOptions(updatedOptions);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dateHandler = (name, date) => {
    validate(name, date);
    if (lodash.isEmpty(date)) {
      return true;
    }

    if (date.$d == "Invalid Date") {
      return true;
    }

    handleChange({
      target: { name, value: moment(date.$d).utc().toISOString() },
    });
  };

  const isValidCurrencyValue = currencyData.some(
    (option) => option.value === eventRules.currency
  );

  // Set a default value if the selectedCurrencyValue is not valid
  const defaultCurrency = isValidCurrencyValue
    ? eventRules.currency
    : currencyData;

  // ------------------------------------------------------------------------------------------------------

  return (
    <div className="editRules">
      <form
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          // Check if the pressed key is 'Enter'
          if (e.key === "Enter") {
            // Prevent the default form submit action
            e.preventDefault();
          }
        }}
      >
        <Box className={classes.accordanContent}>
          <div className={classes.formHead}>
            <h5 className="head1">Timing Rules</h5>
          </div>
          <div className="row">
            <div className="col-md-6">
              {
                // for english auction only
                eventRules.eventFormat === "ENGLISH_AUCTION" && (
                  <div className="mb-3">
                    <Form.Label className={classes.label}>
                      {" "}
                      Can Participants place bids during preview period
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className={classes.input}
                      name="previewPeriod"
                      value={eventRules.previewPeriod}
                      onChange={handleChange}
                    >
                      <option value="YES">Yes (Ignore the start price)</option>
                      <option value="NO">No</option>
                      <option value="PREBID_ABOVE_START_PRICE">
                        YES (Consider the start price)
                      </option>
                    </Form.Select>
                  </div>
                )
              }
              <Box sx={{ marginBottom: "1em" }}>
                <Form.Label className={classes.label}>
                  Bidding start date and time
                </Form.Label>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="datePicker"
                    renderInput={(props) => <TextField {...props} />}
                    value={eventRules.bidStartDateTime}
                    onChange={(date) => {
                      dateHandler("bidStartDateTime", date);
                    }}
                    // disabled if minDate is greater than the current date
                    disabled={moment().isAfter(eventRules.publishDateTime)}
                    minDate={moment()}
                  />
                </LocalizationProvider>
                {errors.bidStartDateTime && (
                  <span>{errors.bidStartDateTime}</span>
                )}
              </Box>
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Overtime period (Minutes)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    name="overTimePeriodInMinutes"
                    onChange={handleChange}
                    className={classes.input}
                    value={eventRules.overTimePeriodInMinutes}
                  />
                </Form.Group>
              </Box>
            </div>
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Auction Publish Date and Time
                  </Form.Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className="datePicker"
                      renderInput={(props) => <TextField {...props} />}
                      value={eventRules.publishDateTime}
                      onChange={(date) => {
                        dateHandler("publishDateTime", date);
                      }}
                      disabled={moment().isAfter(eventRules.publishDateTime)}
                      minDate={moment()}
                    />
                  </LocalizationProvider>
                  {errors.publishDateTime && (
                    <span>{errors.publishDateTime}</span>
                  )}
                </Form.Group>
              </Box>
              {
                // for english auction only
                eventRules.eventFormat === "ENGLISH_AUCTION" && (
                  <Box sx={{ marginBottom: "1em" }}>
                    <Form.Label className={classes.label}>
                      Bidding end date and time
                    </Form.Label>{" "}
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className="datePicker"
                        renderInput={(props) => <TextField {...props} />}
                        value={eventRules.bidEndDateTime}
                        onChange={(date) => {
                          dateHandler("bidEndDateTime", date);
                        }}
                        minDate={moment()}
                      />
                    </LocalizationProvider>
                    {errors.bidEndDateTime && (
                      <span>{errors.bidEndDateTime}</span>
                    )}
                  </Box>
                )
              }
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Bid End Minutes
                  </Form.Label>
                  <Form.Control
                    className={classes.input}
                    type="number"
                    name="extendWithinBidEndMinutes"
                    onChange={handleChange}
                    min="0"
                    value={eventRules.extendWithinBidEndMinutes}
                  />
                </Form.Group>
              </Box>
            </div>
          </div>

          <div className={classes.formHead}>
            <h5 className="heading1">Bidding Rules</h5>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Increment bid amount by
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className={classes.input}
                    name="incrementBidAmountBy"
                    onChange={handleChange}
                    value={eventRules.incrementBidAmountBy}
                  >
                    <option disabled>Value/Percentage</option>
                    <option value="VALUE">Value</option>
                    <option value="PERCENTAGE" disabled>
                      Percentage
                    </option>
                  </Form.Select>
                </Form.Group>
              </Box>
            </div>
            <div className="col-md-6">
              {eventRules.eventFormat === "ENGLISH_AUCTION" && (
                <Box className={classes.forms}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.label}>
                      Allow variable increment/decrement
                    </Form.Label>
                    <Box sx={{ display: "flex" }} className="mt-1">
                      <Form.Group className="">
                        <Form.Check
                          style={{ marginRight: "4px" }}
                          checked={
                            eventRules.variableIncrementDecrement === "YES"
                          }
                          name="variableIncrementDecrement"
                          onChange={handleChange}
                          value="YES"
                          type="radio"
                          label="Yes"
                        />
                      </Form.Group>
                      <Form.Group
                        className={classes.radio}
                        style={{
                          marginLeft: ".6rem",
                        }}
                      >
                        <Form.Check
                          checked={
                            eventRules.variableIncrementDecrement === "NO"
                          }
                          name="variableIncrementDecrement"
                          onChange={handleChange}
                          value="NO"
                          type="radio"
                          label="No"
                        />
                      </Form.Group>
                    </Box>
                  </Form.Group>
                </Box>
              )}
            </div>
          </div>
          <div className={classes.formHead}>
            <h5 className="time1">Currency Rules</h5>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Allow participants to select bidding currency
                  </Form.Label>
                  <SelectOrAddCurrency
                    value={selectedCurrency}
                    setValue={setSelectedCurrency}
                    options={options}
                    setOptions={setOptions}
                  />
                </Form.Group>
              </Box>
            </div>
          </div>

          {eventRules.eventFormat === "ENGLISH_AUCTION" && (
            <>
              <div className={classes.formHead}>
                <h5 className="market">Market Feedback</h5>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Box className={classes.forms}>
                      <Form.Group>
                        <Form.Label className={classes.label}>
                          Show H1/L1 Bid
                        </Form.Label>
                        <Box sx={{ display: "flex" }}>
                          <Form.Group className="">
                            <Form.Check
                              style={{ marginRight: "4px" }}
                              checked={eventRules.showH1L1Bid === "YES"}
                              name="showH1L1Bid"
                              onChange={handleChange}
                              value="YES"
                              type="radio"
                              label="Yes"
                            />
                          </Form.Group>
                          <Form.Group
                            className={classes.radio}
                            style={{
                              marginLeft: ".6rem",
                            }}
                          >
                            <Form.Check
                              checked={eventRules.showH1L1Bid === "NO"}
                              name="showH1L1Bid"
                              onChange={handleChange}
                              value="NO"
                              type="radio"
                              label="No"
                            />
                          </Form.Group>
                          <Form.Group
                            className={classes.radio}
                            style={{
                              marginLeft: ".6rem",
                            }}
                          >
                            <Form.Check
                              checked={
                                eventRules.showH1L1Bid === "IN_TOP_RANKS_ONLY"
                              }
                              name="showH1L1Bid"
                              onChange={handleChange}
                              value="IN_TOP_RANKS_ONLY"
                              type="radio"
                              label="Show top bid rank only"
                            />
                          </Form.Group>
                        </Box>
                      </Form.Group>
                    </Box>
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group>
                      <Form.Label className={classes.label}>
                        Show Rank Till
                      </Form.Label>
                      <Box sx={{ display: "flex" }}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Select
                            className={classes.input}
                            name="showRankTill"
                            onChange={handleChange}
                            disabled={eventRules.showH1L1Bid !== "YES"}
                            value={eventRules.showRankTill}
                          >
                            {[
                              [
                                {
                                  key: "Show Till Rank 1",
                                  value: "SHOW_TILL_1",
                                },
                                {
                                  key: "Show Till Rank 2",
                                  value: "SHOW_TILL_2",
                                },
                                {
                                  key: "Show Till Rank 3",
                                  value: "SHOW_TILL_3",
                                },
                                {
                                  key: "Show Till Last Rank",
                                  value: "SHOW_TILL_LAST",
                                },
                              ].map((item) => (
                                <option value={item.value}>{item.key}</option>
                              )),
                            ]}
                          </Form.Select>
                        </Form.Group>
                      </Box>
                    </Form.Group>
                  </Box>
                </div>
              </div>
            </>
          )}

          <div className={classes.formHead}>
            <h5 className="message">Message</h5>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Event Message
                  </Form.Label>
                  <Form.Control
                    className={classes.input}
                    type="text"
                    name="eventMessage"
                    placeholder="Event Messag e"
                    onChange={handleChange}
                    value={eventRules.eventMessage}
                  />
                </Form.Group>
              </Box>
            </div>
          </div>

          <div className="col">
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Auction Status
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className={classes.input}
                    name="auctionStatus"
                    onChange={handleChange}
                    value={eventRules.auctionStatus}
                  >
                    <option disabled>Auction Status</option>
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Box>
            </div>
          </div>
        </Box>
        <Box className={classes.saveNextBtn}>
          <Button
            className={classes.pre}
            style={{
              marginRight: "2em",
              fontSize: "12.5px",
              marginBottom: "0.5em",
            }}
            onClick={onBack}
          >
            Previous
          </Button>
          <Button
            className={classes.next}
            style={{
              marginRight: "2em",
              fontSize: "12.5px",
              marginBottom: "0.5em",
            }}
            type="submit"
          >
            Update & Next
          </Button>
          <Button
            className={classes.next}
            onClick={() => {
              navigate(routes.dashboard);
            }}
            style={{ fontSize: "12.5px" }}
          >
            Go To Dashboard
          </Button>
        </Box>
      </form>
    </div>
  );
}

export default EditRules;
