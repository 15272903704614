import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import useStyles from "../../../Create/CreateEvent/createEvent.styles";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import ImageUploading from "react-images-uploading";
import Button from "@mui/material/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import colorSchemes from "../../../../../constants/colorSchemes";
import ImageUploading from "react-images-uploading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BsUpload } from "react-icons/bs";
import AuctionTable from "../../AuctionTable";
import getEventDetail from "../../../../../api/getEventDetail";
import getCountries from "../../../../../api/getContriesAPI";
import getStateAPI from "../../../../../api/getStateAPI";
import uploadDocumentEvents from "../../../../../api/uploadDocumentEvents";
import { eventUpdateAPI } from "../../../../../api/adminAPI/eventUpdateAPI";
import { omit } from "lodash";
import Loader from "../../../../../common/Loader";

import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import moment from "moment";

function EditEvent({ AuctionTable, EditTabEvent, eventId }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isEnglishAuction, setIsEnglishAuction] = useState(false);
  const [isDutchAuction, setIsDutchAuction] = useState(false);
  const [isJapaneseAuction, setIsJapaneseAuction] = useState(false);

  const [eventInfo, setEventInfo] = useState({
    auctionTitle: "",
    referenceNumber: "",
    description: "",
    eventType: "",
    testProject: "false",
    streetOne: "",
    streetTwo: "",
    country: "",
    province: "",
    bidType: "",
    city: "",
    postalCode: "",
    eventImage: "",
    auctionCatalogue: "",
    bidStartDateTime: "",
    visibility: "",
  });

  const [eventData, setEventsData] = useState([]);

  const eventDetails = async (eventId) => {
    try {
      const payload = { auctionId: eventId };
      const { data } = await getEventDetail(payload);

      if (data) {
        setEventsData(data);
        setEventInfo({
          auctionTitle: data.auctionTitle,
          referenceNumber: data.referenceNumber,
          description: data.description,
          eventType: data.eventType,
          testProject: String(data.testProject),
          streetOne: data.streetOne,
          streetTwo: data.streetTwo,
          country: data.countryId,
          province: data.provinceId,
          city: data.city,
          bidType: data.bidType,
          postalCode: data.postalCode,
          eventImage: data.eventImage,
          auctionCatalogue: data.auctionCatalogue,
          bidStartDateTime: data.bidStartDateTime,
          visibility: data.visibility,
        });
        if (data.eventFormat === "ENGLISH_AUCTION") setIsEnglishAuction(true);
        if (data.eventFormat === "DUTCH_AUCTION") setIsDutchAuction(true);
        if (data.eventFormat === "JAPANESE_AUCTION") setIsJapaneseAuction(true);
        stateData(data.countryId);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventDetails(eventId);
    return () => {
      return false;
    };
  }, [eventId]);

  //Get Countries Nad States

  const [countriesList, updateCountriesList] = useState({
    apiState: "loading",
    list: [],
  });

  const [stateList, updateStateList] = useState({
    apiState: "loading",
    list: [],
  });

  const countryList = async () => {
    try {
      const { data } = await getCountries();

      if (data) {
        if (data) updateCountriesList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    countryList();
    return () => {
      return false;
    };
  }, []);

  const stateData = async (countryId) => {
    try {
      const { data } = await getStateAPI(countryId);

      if (data) {
        if (data) updateStateList({ apiState: "success", list: data });
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    stateData(eventInfo.country);
    return () => {
      return false;
    };
  }, []);

  //Image Upload Functionality start

  const [images, setImages] = React.useState("");
  const [imagesUploaded, setImagesuploaded] = React.useState([]);
  const maxNumber = 5;
  const [eventUploadInfo, setEventUploadInfo] = React.useState({
    document: "",
  });

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    imageUpload(imageList);
  };
  const imageUpload = async (images) => {
    try {
      setIsLoading(true);
      images.map(async (image, index) => {
        eventUploadInfo.document = image["file"];
        const { data } = await uploadDocumentEvents(eventUploadInfo);
        setImagesuploaded(data);
        eventInfo.eventImage = data.documentId;

        if (data) {
          setIsLoading(false);
          toast("Image Uploaded Successfully!");
        }
      });

      // toast.success("Slide Effect!", {
      //   transition: Slide,
      //   position: "top-left",
      // });
    } catch (error) {
      if (error.response) {
        setIsLoading(false);
        error.response.data.error.map((errors, index) => {
          toast(errors);
        });
      } else if (error.request) {
        toast("Something went wrong!");
      } else {
        console.log("Error", error.message.code);
      }
    }
  };

  //Image Upload Functionality End

  // Catelogue PDF Upload Start

  const [imagesCatalogue, setImagesCatalogue] = React.useState("");
  const [imagesUploadedCatalogue, setImagesuploadedCatalogue] = React.useState(
    []
  );
  //const maxNumber = 5;
  const [eventUploadInfoCatalogue, setEventUploadInfoCatalogue] =
    React.useState({
      document: "",
    });

  const onChangeCatalogue = (catalogueImageList, addUpdateIndex) => {
    setImagesCatalogue(catalogueImageList);
    imageUploadCatalogue(catalogueImageList);
  };
  const imageUploadCatalogue = async (images) => {
    try {
      setIsLoading(true);
      images.map(async (image, index) => {
        eventUploadInfoCatalogue.document = image["file"];
        const { data } = await uploadDocumentEvents(eventUploadInfoCatalogue);
        setImagesuploadedCatalogue(data);
        eventInfo.auctionCatalogue = data.documentId;

        if (data) {
          setIsLoading(false);
          toast("Catalogue Uploaded Successfully!");
        }
      });
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.error);
        error.response.data.error.map((errors, index) => {
          toast(errors);
          setIsLoading(false);
        });
      } else if (error.request) {
        toast("Something went wrong!");
      } else {
        console.log("Error", error.message.code);
      }
    }
  };

  //catelogue PDF Upload End

  const handleChange = (event) => {
    event.persist();
    validate(event, event.target.name, event.target.value);
    if (event.target.name === "country") stateData(event.target.value);
    setEventInfo({ ...eventInfo, [event.target.name]: event.target.value });
  };

  // Validation Form

  const [errors, setErrors] = React.useState("");
  const validate = (event, name, value) => {
    //A function to validate each input values
    const re = /^[A-Za-z ]+$/;
    switch (name) {
      case "state":
        if (!re.test(value)) {
          // we will set the error state

          setErrors({
            ...errors,
            state: "state should contains only Letters",
          });
        } else {
          // set the error state empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "state");
          setErrors(newObj);
        }
        break;

      case "city":
        if (!re.test(value)) {
          // we will set the error city

          setErrors({
            ...errors,
            city: "City should contains only Letters",
          });
        } else {
          // set the error city empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "city");
          setErrors(newObj);
        }
        break;

      case "country":
        if (!re.test(value)) {
          // we will set the error country

          setErrors({
            ...errors,
            country: "country should contains only Letters",
          });
        } else {
          // set the error country empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "country");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const updateEventInfo = { ...eventInfo };
      delete updateEventInfo.bidStartDateTime;

      if (eventInfo.testProject == "true") {
        updateEventInfo.testProject = true;
      }
      if (eventInfo.testProject == "false") {
        updateEventInfo.testProject = false;
      }

      setIsLoading(true);
      const { data } = await eventUpdateAPI(updateEventInfo, eventId);
      toast("Event Upadate Successfully!");
      //EditTabEvent
      //console.log(eventId)
      setTimeout(() => {
        setIsLoading(false);
        EditTabEvent(eventId);
      }, 1500);
    } catch (error) {
      console.log(error.response.data.error);
      if (error.response) {
        toast(error.response.data.error);
      } else if (error.request) {
        toast("Something Went Wrong!");
      } else {
        console.log("Error", error.message.code);
      }
    }
  };

  // console.clear();

  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // for onchange event
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileError, setPdfFileError] = useState("");

  // for submit event
  const [viewPdf, setViewPdf] = useState(null);

  // onchange event
  const fileType = ["application/pdf"];
  const handlePdfFileChange = async (e) => {
    let selectedFile = e.target.files[0];
    setIsLoading(true);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = async (e) => {
          setPdfFile(e.target.result);
          setPdfFileError("");
          eventUploadInfoCatalogue.document = selectedFile;
          const { data } = await uploadDocumentEvents(eventUploadInfoCatalogue);
          setImagesuploadedCatalogue(data);
          eventInfo.auctionCatalogue = data.documentId;

          if (data) {
            setIsLoading(false);
            setViewPdf(e.target.result);
            toast("Catalogue Uploaded Successfully!");
          }
        };
      } else {
        setPdfFile(null);
        setPdfFileError("Please select valid pdf file");
        setIsLoading(false);
      }
    } else {
      console.log("select your file");
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading === true && <Loader isLoading={isLoading} />}

      <div className={classes.event}>
        <div className="row">
          <div className="col-md-4">
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "left",
                padding: "1em",
                fontSize: "20px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
              }}
            >
              <Button className="btn btn-group" onClick={AuctionTable}>
                <ArrowBackIcon sx={{ color: "#000" }} />
              </Button>{" "}
              Event No:{eventData.eventNumber}
            </Typography>
          </div>
          <div className="col-md-4">
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "center",
                padding: "1em",
                fontSize: "20px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
              }}
            >
              Auction name {eventData.auctionTitle}
            </Typography>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="section-event mb-5">
              <div className="row">
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="mb-3">
                      <Form.Label className={classes.label}>
                        Auction Title
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        placeholder="Auction Title"
                        name="auctionTitle"
                        defaultValue={eventData.auctionTitle}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="mb-3">
                      <Form.Label className={classes.label}>
                        Refrence Number
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="referenceNumber"
                        defaultValue={eventData.referenceNumber}
                        onChange={handleChange}
                        required
                        // placeholder="Refrence No."
                      />
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Label className={classes.label}>
                      Description (Limit 250 characters)
                    </Form.Label>
                    <Form.Control
                      className={classes.input}
                      as="textarea"
                      name="description"
                      style={{ height: "124px" }}
                      defaultValue={eventData.description}
                      onChange={handleChange}
                      maxLength={250}
                      required
                    />
                  </Box>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Form.Label className={classes.label}>
                      Event Type
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className={classes.input}
                      name="eventType"
                      required
                      value={eventData.eventType}
                      disabled={true}
                      onChange={handleChange}
                    >
                      <option>Select Event Type</option>
                      <option selected value="FORWARD_AUCTION">
                        FORWARD AUCTION
                      </option>
                      <option selected value="REVERSE_AUCTION">
                        REVERSE AUCTION
                      </option>
                    </Form.Select>
                  </div>
                  <Box className={classes.forms}>
                    <Form.Group controlId="ownerOfLots">
                      <Form.Label className={classes.label}>
                        Owner Of Lots
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="ownerOfLots"
                        required
                        placeholder="Owner Of Lots"
                        defaultValue={eventData.ownerOfLots}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-6">
                  <div className="mb-3" style={{ marginTop: ".8rem" }}>
                    <Form.Label className={classes.label}>
                      Auction visibility
                    </Form.Label>
                    <Form.Select
                      className={classes.input}
                      name="visibility"
                      onChange={handleChange}
                      value={eventInfo.visibility}
                      required
                    >
                      <option>Select auction visibility</option>
                      <option value="PUBLIC_AUCTION">Public auction</option>
                      <option value="PARTICIPANTS_ONLY_AUCTION">
                        Participants only auction
                      </option>
                      <option value="SELLER_BIDDERS_ONLY_AUCTION">
                        Seller bidders only auction
                      </option>
                    </Form.Select>
                  </div>
                </div>
                {isEnglishAuction && (
                  <div className="col-md-6">
                    <div className="mb-3" style={{ marginTop: ".8rem" }}>
                      <Form.Label className={classes.label}>
                        Bid Type
                      </Form.Label>
                      <Form.Select
                        className={classes.input}
                        name="bidType"
                        onChange={handleChange}
                        value={eventInfo.bidType}
                        // disbaled if auction has been started already
                        disabled={moment().isAfter(eventData.bidStartDateTime)}
                        required
                      >
                        <option>Select Bid Type</option>
                        <option value="RELATIVE_TO_LAST_BID_BY_ANY_USER">
                          Relative to last bid by any user
                        </option>
                        <option value="RELATIVE_TO_LAST_BID_BY_SELF">
                          Relative to user's own last bid
                        </option>
                      </Form.Select>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <Box className={classes.forms}>
                        <Form.Group className="mt-3">
                          <Form.Label className={classes.label}>
                            Test Project
                          </Form.Label>
                          <Box sx={{ display: "flex" }}>
                            <Form.Group className="">
                              <Form.Check
                                checked={
                                  eventInfo.testProject == "true" ? true : false
                                }
                                name="testProject"
                                onChange={handleChange}
                                value="true"
                                type="radio"
                                label="Yes"
                              />
                            </Form.Group>
                            <Form.Group className={classes.radio}>
                              <Form.Check
                                checked={
                                  eventInfo.testProject == "false"
                                    ? true
                                    : false
                                }
                                name="testProject"
                                onChange={handleChange}
                                value="false"
                                type="radio"
                                label="No"
                              />
                            </Form.Group>
                          </Box>
                        </Form.Group>
                      </Box>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-12">
                  <Box className={classes.break} />
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="">
                      <Form.Label className={classes.label}>
                        Vanue Address
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="streetOne"
                        required
                        placeholder="Street Address"
                        defaultValue={eventData.streetOne}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="">
                      <Form.Label
                        className={classes.label}
                        style={{ visibility: "hidden" }}
                      >
                        Vanue Address
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="streetTwo"
                        required
                        placeholder="Street Address Second"
                        defaultValue={eventData.streetTwo}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="">
                      <Form.Label
                        className={classes.label}
                        style={{ visibility: "hidden" }}
                      >
                        Venue Address
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="city"
                        required
                        placeholder="City"
                        defaultValue={eventData.city}
                        onChange={handleChange}
                      />
                      {/* {errors.city && <span>{errors.city}</span>} */}
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="">
                      <Form.Label
                        className={classes.label}
                        style={{ visibility: "hidden" }}
                      >
                        Vanue Address
                      </Form.Label>
                      <Form.Control
                        className={classes.input}
                        type="text"
                        name="postalCode"
                        required
                        placeholder="Postal Code"
                        defaultValue={eventData.postalCode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="">
                      <Form.Label
                        className={classes.label}
                        style={{ visibility: "hidden" }}
                      >
                        Vanue Address
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className={classes.input}
                        name="country"
                        onChange={handleChange}
                        required={true}
                        value={eventInfo.country}
                      >
                        <option>Country</option>
                        {countriesList.list.map((country, index) => {
                          return (
                            <option value={country.id}>{country.name}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box className={classes.forms}>
                    <Form.Group className="">
                      <Form.Label
                        className={classes.label}
                        style={{ visibility: "hidden" }}
                      >
                        Vanue Address
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className={classes.input}
                        name="province"
                        onChange={handleChange}
                        required={true}
                        value={eventInfo.province}
                      >
                        <option>States</option>
                        {stateList.list.map((state, index) => {
                          return <option value={state.id}>{state.name}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Box>
                </div>
                <div className="col-md-12">
                  <Box className={classes.break} />
                </div>
                <div className="col-md-12">
                  <div className={classes.uploader}>
                    {/* Event Images */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            Old Event Image
                            <div className="row">
                              <div className="col-md-4">
                                <div className="image-item">
                                  <img
                                    src={eventData.eventImageUrl}
                                    alt=""
                                    width="100"
                                    className={classes.imagesStyle}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />

                        <ImageUploading
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                          acceptType={["jpg", "jpeg", "png"]}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            dragProps,
                            errors,
                          }) => (
                            <>
                              Event Image(JPG,JPEG,PNG)
                              <p className={classes.imageSize}>
                                Dimension: 260px X 200px
                              </p>
                              <div className="upload__image-wrapper">
                                <div className="col-md-12">
                                  <Box sx={{ display: "flex" }}>
                                    <Box sx={{ width: "100%" }}>
                                      <Button
                                        variant="contained"
                                        component="label"
                                        className={classes.upload}
                                        sx={{
                                          display: "block",
                                          textAlign: "right",
                                        }}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        <BsUpload
                                          className={classes.uploadIcon}
                                        />
                                      </Button>
                                    </Box>
                                  </Box>
                                </div>
                                <div className="col-md-12">
                                  <div className="row">
                                    {imageList.map((image, index) => (
                                      <div className="col-md-4">
                                        <div key={index} className="image-item">
                                          <img
                                            src={image["data_url"]}
                                            alt=""
                                            width="100"
                                            className={classes.imagesStyle}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              {errors && (
                                <div>
                                  {errors.maxNumber && (
                                    <span>
                                      Number of selected images exceed maxNumber
                                    </span>
                                  )}
                                  {errors.acceptType && (
                                    <span>
                                      Your selected file type is not allow
                                    </span>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </ImageUploading>
                      </div>

                      {/* catalogue Pdf */}
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="heightFix">
                              Previous Uploaded Catalogue
                              <div className="row">
                                <div className="col-md-12">
                                  {/* <div className="image-item">
                                <img
                                  src={eventData.auctionCatalogueUrl}
                                  alt=""
                                  width="100"
                                  className={classes.imagesStyle}
                                />
                              </div> */}
                                  <a
                                    href={eventData.auctionCatalogueUrl}
                                    target="_Blank"
                                    color="inherit"
                                    className="btn btn-group download"
                                    aria-label="open drawer"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Old Catelogue
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        Catalogue (PDF)
                        <p
                          className={classes.imageSize}
                          style={{ display: "none" }}
                        >
                          Dimension: 260px X 200px
                        </p>
                        <input
                          type="file"
                          accept=".pdf"
                          className="form-control"
                          onChange={handlePdfFileChange}
                        />
                        {pdfFileError && (
                          <div className="error-msg">{pdfFileError}</div>
                        )}
                        <br></br>
                        <div
                          className="pdf-container"
                          // style={{ height: "30em" }}
                        >
                          {viewPdf ? (
                            <>
                              <div style={{ display: "block", height: "30em" }}>
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                  <Viewer
                                    fileUrl={viewPdf}
                                    plugins={[defaultLayoutPluginInstance]}
                                  />
                                </Worker>
                              </div>
                            </>
                          ) : (
                            <p style={{ height: "2em" }}>No Pdf Selected</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={classes.saveBtn}>
                    <Button className={classes.next} type="submit">
                      Save & Next
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditEvent;
