import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Button from "@mui/material/Button";
import useStyles from "../../dashAuction.style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import buyerLotsSummaryAPI from "../../../../../api/adminAPI/buyerLotsSummaryAPI";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  getToken,
  getUserData,
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../../common";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import lodash from "lodash";
import { FaRegFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";

function AuctionSummary({ buyerId, eventId, BidlogFirst }) {
  const classes = useStyles();
  const getZone = getTimeZone() ? getTimeZone() : localTimeZone();

  const [tableBuyerLotData, setTableBuyerLotData] = useState([]);

  const eventBuyerLotDetails = async (eventId, buyerId) => {
    try {
      const { data } = await buyerLotsSummaryAPI(eventId, buyerId);

      if (data) {
        let id = 1;
        const eventsData = data.map((eventItem, index) => {
          return {
            id: id++,
            lotNumber: eventItem.lotNumber,
            eventName: eventItem.auctionTitle,
            quantity: eventItem.quantity,
            unit: eventItem.unit,
            startPrice: eventItem.startPrice,
            increment: eventItem.increment,
            startDateAndTime: moment(eventItem.startDateAndTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            endDateAndTime: moment(eventItem.endDateAndTime)
              .tz(getZone)
              .format("DD/MM/YYYY hh:mm:ss"),
            bidderName: `${eventItem.user.firstName} ${eventItem.user.lastName}`,
            NoOfBid: eventItem.bidDetails.totalBids,
            participants: eventItem.participants,
            amount: eventItem.amount,
            result: eventItem.result,
            rankDetails: eventItem.rankDetails,
            StartBidPrice: eventItem.bidDetails.firstBid,
            endBidPrice: eventItem.bidDetails.lastBid,
          };
        });

        setTableBuyerLotData(eventsData);
      } else throw new Error(data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventBuyerLotDetails(eventId, buyerId);
    return () => {
      return false;
    };
  }, [eventId, buyerId]);

  const nextColumns = [
    {
      name: "Sr.",
      selector: (row) => Number(row.id), // Ensure numeric sorting
      cell: (data) => String(data.id),
      sortable: true,
      width: "80px",
    },
    {
      name: "Lot No",
      selector: (row) => String(row.lotNumber), // Sort as a string
      cell: (data) => String(data.lotNumber),
      sortable: true,
      width: "100px",
    },
    {
      name: "Event Name",
      selector: (row) => row.eventName?.toLowerCase() || "", // Case-insensitive sorting
      cell: ({ eventName }) => (
        <Tooltip
          title={eventName}
          placement="top-start"
          TransitionComponent={Zoom}
        >
          <span className={classes.ellipsisText}>{eventName}</span>
        </Tooltip>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Qty",
      selector: (row) => Number(row.quantity), // Ensure numeric sorting
      cell: (data) => Number(data.quantity),
      sortable: true,
      width: "100px",
    },
    {
      name: "Unit",
      selector: (row) => String(row.unit),
      cell: (data) => String(data.unit),
      sortable: true,
      width: "100px",
    },
    {
      name: "Start Price",
      selector: (row) => Number(row.startPrice), // Ensure numeric sorting
      cell: (data) => Number(data.startPrice)?.toLocaleString(),
      sortable: true,
      width: "120px",
    },
    {
      name: "Increment",
      selector: (row) => Number(row.increment), // Ensure numeric sorting
      cell: (data) => Number(data.increment),
      sortable: true,
      width: "120px",
    },
    {
      name: "Start Date & Time",
      selector: (row) => new Date(row.startDateAndTime).getTime(), // Ensure date sorting
      cell: ({ startDateAndTime }) => String(startDateAndTime),
      sortable: true,
      width: "180px",
    },
    {
      name: "End Date & Time",
      selector: (row) => new Date(row.endDateAndTime).getTime(), // Ensure date sorting
      cell: ({ endDateAndTime }) => String(endDateAndTime),
      sortable: true,
      width: "180px",
    },
    {
      name: "Participant",
      selector: (row) => Number(row.participants) || 0, // Ensure numeric sorting
      cell: (data) => String(data.participants),
      sortable: true,
      width: "120px",
    },
    {
      name: "Bidder Name",
      selector: (row) => row.bidderName?.toLowerCase() || "", // Case-insensitive sorting
      cell: (data) => String(data.bidderName),
      sortable: true,
      width: "130px",
    },
    {
      name: "No. of Bid",
      selector: (row) => Number(row.NoOfBid) || 0, // Ensure numeric sorting
      cell: (data) => Number(data.NoOfBid || 0),
      sortable: true,
      width: "120px",
    },
    {
      name: "Buyer Start Bid",
      selector: (row) => Number(row.StartBidPrice) || 0, // Ensure numeric sorting
      cell: (data) => Number(data.StartBidPrice || 0)?.toLocaleString(),
      sortable: true,
      width: "140px",
    },
    {
      name: "Buyer Last Bid",
      selector: (row) => Number(row.endBidPrice) || 0, // Ensure numeric sorting
      cell: (data) => Number(data.endBidPrice || 0)?.toLocaleString(),
      sortable: true,
      width: "140px",
    },
    {
      name: "Amount",
      selector: (row) => Number(row.amount) || 0, // Ensure numeric sorting
      cell: (data) => Number(data.amount || 0)?.toLocaleString(),
      sortable: true,
      width: "100px",
    },
    {
      name: "Rank",
      selector: (row) => row.rankDetails?.toLowerCase() || "-", // Case-insensitive sorting
      cell: (data) => String(data.rankDetails || "-"),
      sortable: true,
      width: "100px",
    },
    {
      name: "Result",
      selector: (row) => row.result?.toLowerCase() || "-", // Case-insensitive sorting
      cell: (data) => String(data.result || "-"),
      sortable: true,
      width: "120px",
    },
  ];

  const exportToXLSX = (tableData, tableHeader) => {
    const headerColumns = lodash.map(tableHeader, ({ name }) => {
      if (name != "Action") {
        return name;
      }
    });
    const dataColumns = lodash.map(tableData, (columns) => [
      columns.id,
      columns.lotNumber,
      columns.eventName,
      columns.quantity,
      columns.unit,
      columns.startPrice,
      columns.increment,
      columns.startDateAndTime,
      columns.endDateAndTime,
      columns.participants,
      columns.bidderName,
      columns.NoOfBid,
      columns.StartBidPrice,
      columns.endBidPrice,
      columns.amount,
      columns.rankDetails,
      columns.result,
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headerColumns, ...dataColumns]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "buyer-event-summary.xlsx");
  };

  const ExportButton = () => (
    <Button onClick={() => exportToXLSX(tableBuyerLotData, nextColumns)}>
      <FaRegFileExcel
        style={{ fontSize: "30px", color: "#04aa18", paddingTop: "4px" }}
      />
    </Button>
  );

  return (
    <>
      <div className="AuctionTable">
        <h3>
          <Button sx={{ color: "#000" }} onClick={BidlogFirst}>
            <ArrowBackIcon />
          </Button>
          Buyer Lots Summary
        </h3>
        <DataTableExtensions
          print={false}
          export={false}
          exportHeaders={false}
          columns={nextColumns}
          data={tableBuyerLotData}
        >
          <DataTable
            noHeader={false}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            highlightOnHover
            className="DataTable"
            actions={<ExportButton />}
          />
        </DataTableExtensions>
      </div>
    </>
  );
}

export default AuctionSummary;
